import { clientEndpoint } from 'src/constants'

const goBackInfo = {
  article: {
    link: `${clientEndpoint}/article`,
    txt: 'Назад к статьям'
  },
  video: {
    link: `${clientEndpoint}/video`,
    txt: 'Назад к видео'
  },
  webinar: {
    link: `${clientEndpoint}/webinar`,
    txt: 'Назад к вебинарам'
  }
}

const materialExample = {
  article: `${clientEndpoint}/article/example`,
  video: `${clientEndpoint}/video/example`
}

export { goBackInfo, materialExample }
