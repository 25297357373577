import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './width-limiter.module.scss'

interface IMaxWidthLimiter {
  children: ReactNode
  noTopPadding?: boolean
  noBottomPadding?: boolean
  noLeftPadding?: boolean
  noRightPadding?: boolean
  fullHeight?: boolean
  modificator?: string
}

const WidthLimiter: FC<IMaxWidthLimiter> = ({
  noTopPadding,
  noBottomPadding,
  noLeftPadding,
  noRightPadding,
  fullHeight,
  modificator,
  children
}) => (
  <div
    className={clsx(styles.main, {
      [styles.noTopPadding]: noTopPadding,
      [styles.noBottomPadding]: noBottomPadding,
      [styles.noLeftPadding]: noLeftPadding,
      [styles.noRightPadding]: noRightPadding,
      [styles.fullHeight]: fullHeight,
      [modificator as string]: modificator
    })}
  >
    <div className={styles.inner}>{children}</div>
  </div>
)

export default WidthLimiter
