import { IObj } from 'src/constants/types'

const ENTER_CORRECT_NAME = 'Введите корректное имя'
const ENTER_LAST_NAME = 'Введите корректную фамилию'
const ENTER_SECOND_NAME = 'Введите корректное отчество'
const SAVING_MESSAGE = 'Одну секунду, сохраняем данные'
const UNKNOWN_ERROR = 'Непредвиденная ошибка. Попробуйте еще раз'

const TEST_NAME_REG_EXP = new RegExp('^[а-яёa-z -]+$', 'i')
const TEST_EMAIL_REG_EXP = /^[-+\w.]+@([A-z0-9][-A-z0-9]+\.)+[a-z]{2,4}$/iu

const NOT_FROM_RUSSIA = 1

type IFieldData = IObj<{
  nameInput: string
  placeholder: string
  type: string
  mask?: string
  instanceId?: string
  autoComplete?: string
  tabIndex?: number
}>

enum FieldsNameVoc {
  firstName = 'firstName',
  email = 'email',
  textarea = 'textarea',
  password = 'password'
}

const fieldData: IFieldData = {
  [FieldsNameVoc.firstName]: {
    nameInput: 'Имя',
    placeholder: 'Введите имя',
    type: 'text',
    mask: ''
  },

  [FieldsNameVoc.email]: {
    nameInput: 'Email',
    placeholder: 'Введите Email',
    type: 'email',
    mask: '',
    autoComplete: 'email'
  },

  [FieldsNameVoc.password]: {
    nameInput: 'Пароль',
    placeholder: 'Введите пароль',
    type: 'text',
    mask: ''
  }
}
// Валидация для react-hook-form
const checkFLSName = (values: string, message: string) => {
  if (values && values.length !== 0) {
    if (values.length > 20 || values.length < 2 || !TEST_NAME_REG_EXP.test(values as string))
      return message
  }
}

const checkTextarea = (values: string) => {
  if (values && values.length !== 0 && values.length < 5) return 'Слишком короткий вопрос'
}

const checkPassword = (values: string) => {
  if (!values?.length) return 'Введите пароль'
}

const firstNameValidation = {
  required: 'Имя не введено',
  validate: (values: string) => checkFLSName(values, ENTER_CORRECT_NAME)
}

const emailValidation = {
  required: 'Email не введен',
  validate: (values: string) => {
    if (!TEST_EMAIL_REG_EXP.test(values as string)) return 'Введите корректный email'
  }
}

const texValidation = {
  required: 'Введите вопрос',
  validate: (values: string) => checkTextarea(values)
}

const passwordValidation = {
  required: 'Введите пароль',
  validate: (value: string) => checkPassword(value)
}

export {
  firstNameValidation,
  emailValidation,
  texValidation,
  passwordValidation,
  fieldData,
  TEST_EMAIL_REG_EXP,
  TEST_NAME_REG_EXP,
  FieldsNameVoc,
  NOT_FROM_RUSSIA,
  ENTER_CORRECT_NAME,
  ENTER_LAST_NAME,
  ENTER_SECOND_NAME,
  UNKNOWN_ERROR,
  SAVING_MESSAGE
}
