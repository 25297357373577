import { toast } from 'react-toastify'

import 'react-toastify/dist/ReactToastify.css'

const alertError = () =>
  toast.error('Ошибка загрузки', {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: 0,
    theme: 'light'
  })

const alertAuthError = () =>
  toast.error('Ошибка авторизации: неверный логин или пароль', {
    position: 'top-right',
    autoClose: 2000,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: 0,
    theme: 'light'
  })

const alertSend = () => toast.loading('Отправка...')

const dismissAll = () => toast.dismiss()

export { alertError, alertSend, alertAuthError, dismissAll }
