import { FC, ReactNode } from 'react'
import clsx from 'clsx'

import styles from './blur-wrapper.module.scss'

interface IBlurWrapper {
  children?: ReactNode
  modificator?: string
}

const BlurWrapper: FC<IBlurWrapper> = ({ modificator, children }) => {
  return (
    <div
      className={clsx(styles.blurOverlay, {
        [modificator as string]: modificator
      })}
    >
      <p className={styles.blutTitle}>{children}</p>
    </div>
  )
}

export default BlurWrapper
