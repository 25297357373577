import { FC } from 'react'

import styles from './percent-tag.module.scss'
import clsx from 'clsx'

interface IPercentTag {
  value: number
}

const PercentTag: FC<IPercentTag> = ({ value }) => {
  const isValuePositive = value > 100
  const isValueZero = value === 100

  return (
    <div
      className={clsx(styles.wrapper, {
        [styles.wrapperPositive]: isValuePositive,
        [styles.wrapperNegative]: !isValuePositive && !isValueZero,
        [styles.wrapperZero]: isValueZero
      })}
    >
      <p
        className={clsx(styles.percent, {
          [styles.percentPositive]: isValuePositive,
          [styles.percentNegative]: !isValuePositive && !isValueZero
        })}
      >
        {Math.abs(100 - value)}%
      </p>
    </div>
  )
}

export default PercentTag
