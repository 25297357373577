import { FC } from 'react'

import ValueWidget from '../parts/valueWidget/valueWidget'

import { materialData, TInitState, vidgetName } from 'src/pages/article/data'

import common from 'src/styles/variables/common.module.scss'

interface ICoverageArticleProps {
  statData: TInitState
  materialType: 'article' | 'video'
}

const CoverageArticle: FC<ICoverageArticleProps> = ({ statData, materialType }) => {
  return (
    <>
      <h2 className={common.blockName}>Охваты</h2>
      <div className={common.valueWidgetWrapper}>
        {materialData[materialType].map((vidget: { title: string; name: keyof typeof vidgetName; value?: string }) => {
          if (vidget.name === vidgetName.uniqueViews)
            return (
              <ValueWidget
                key={vidget.name}
                title={vidget.title}
                info={String(statData[vidget.name])}
                benchmark={statData.benchmark}
                percentDiff={
                  statData.benchmark ? Math.round((Number(statData[vidget.name]) / statData.benchmark) * 100) : null
                }
              />
            )
          return (
            <ValueWidget
              key={vidget.name}
              title={vidget.title}
              info={
                String(statData[vidget.name]) !== 'null' && String(statData[vidget.name]) !== 'undefined'
                  ? `${statData[vidget.name]}${vidget?.value || ''}`
                  : null
              }
            />
          )
        })}
      </div>
    </>
  )
}

export default CoverageArticle
