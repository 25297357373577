import { materialExample } from 'src/constants/links-data'

type IContent = {
  [key: string]: {
    title: string
    titleCase: string
    pointsCase: string[]
    btnCase: string
    btnLink: string
    titleSale: string | null
    subtitleSale: string
  }
}

const content: IContent = {
  article: {
    title: 'Вы еще не опубликовали ни одной статьи на Medpoint 😢',
    titleCase:
      'Публикация статей на Medpoint — это уникальная возможность поделиться исследованиями, клиническими случаями и опытом применения вашего препарата с врачами. Вы можете опубликовать статью на одну из следующих тем:',
    pointsCase: [
      'Исследования о вашем препарате, чтобы врачи узнали о новых методах лечения и их эффективности',
      'Клинический случай, чтобы поделиться опытом применения вашего препарата на реальном примере из практики и продемонстрировать его эффективность в решении конкретных медицинских проблем',
      'Актуализация проблемы и как ваш препарат помогает в терапии этого заболевания, чтобы врачи узнали, как препарат может помочь их пациентам'
    ],
    btnCase: 'Кейс компании НоваМедика',
    btnLink: materialExample.article,
    titleSale:
      'Если у вас еще нет готовых материалов, наша медицинская команда готова помочь написать статью с учетом ваших задач и целей, создав содержательный контент, который заинтересует врачей.',
    subtitleSale: 'Мы предлагаем скидку 25% на публикацию первой статьи!'
  },
  video: {
    title: 'Вы еще не опубликовали ни одного видео на Medpoint 😢',
    titleCase:
      'Публикация видео на Medpoint — это уникальная возможность поделиться исследованиями, клиническими случаями и опытом применения вашего препарата с врачами. Вы можете опубликовать видео на одну из следующих тем:',
    pointsCase: [
      'Разбор нового исследования, чтобы рассказать о важных данных, полученных в ходе исследования, и показать преимущества вашего препарата перед конкурентами',
      'Клинический случай, чтобы поделиться опытом применения вашего препарата на реальном примере из практики и продемонстрировать его эффективность в решении конкретных медицинских проблем',
      'Интервью с лектором об опыте применения вашего препарата, чтобы врачи могли получить полезные советы от профессионалов.'
    ],
    btnLink: materialExample.video,
    btnCase: 'Кейс компании Merz',
    titleSale: null,
    subtitleSale: 'К тому же, мы предлагаем скидку 25% на публикацию первого видео!'
  }
}

export { content }
