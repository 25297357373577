import { FC } from 'react'

import Warning from '../parts/warning/warning'
import Table from '../parts/table/table'

import { createExcel } from 'src/utils/createXLSX/createXlsx'
import common from 'src/styles/variables/common.module.scss'
import styles from './members-report-article.module.scss'

interface IMembersReportArticle {
  materialType: 'article' | 'video'
  audience: any[]
  id: number
}

const MembersReportArticle: FC<IMembersReportArticle> = ({ materialType, audience, id }) => {
  const downloadUsers = () => {
    const titles = ['Cпециальность', 'Количество пользователей']
    if (audience) createExcel({ data: audience, titles, isWebinar: false, id: Number(id) })
  }

  return (
    <>
      <h2 className={common.blockName}>
        Состав {materialType === 'video' ? 'зрителей' : 'читателей'}
      </h2>
      <div className={styles.warningWrapper}>
        <Warning />
      </div>
      <Table data={audience} materialType={materialType} handleClick={downloadUsers} />
    </>
  )
}

export default MembersReportArticle
