import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider, Helmet } from 'react-helmet-async'

import App from './App'
import reportWebVitals from './reportWebVitals'
import '@fontsource/source-sans-pro/400.css'
import '@fontsource/source-sans-pro/600.css'
import '@fontsource/source-sans-pro/700.css'
import '@fontsource/rubik/300-italic.css'
import './index.module.scss'

const GA_TRACKING_ID = 'G-N0ZYKZWMT0'
const AMPLITUDE_KEY = '19a5b3ac412b0e88e6c609abbc5a717f'

const helmetContext = {}

ReactDOM.render(
  <React.StrictMode>
    <HelmetProvider context={helmetContext}>
      <BrowserRouter>
        <Helmet>
          <meta name="description" content="Nested component" />
          <script async src={`https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`}></script>
          <script>
            {`
              window.dataLayer = window.dataLayer || [];
              function gtag(){dataLayer.push(arguments);}
              gtag('js', new Date());
              gtag('config', '${GA_TRACKING_ID}');
            `}
          </script>
          <script>
            {`
              (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
              new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
              j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
              'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
              })(window,document,'script','dataLayer','GTM-59V84NH');
            `}
          </script>
          <script>
            {` 
             !function(e,t){var n=e.amplitude||{_q:[],_iq:{}},i=t.createElement("script");i.type="text/javascript",i.integrity="sha384-girahbTbYZ9tT03PWWj0mEVgyxtZoyDF9KVZdL+R53PP5wCY0PiVUKq0jeRlMx9M",i.crossOrigin="anonymous",i.async=!0,i.src="https://cdn.amplitude.com/libs/amplitude-7.2.1-min.gz.js",i.onload=function(){e.amplitude.runQueuedFunctions||console.log("[Amplitude] Error: could not load SDK")};var r=t.getElementsByTagName("script")[0];function o(e,t){e.prototype[t]=function(){return this._q.push([t].concat(Array.prototype.slice.call(arguments,0))),this}}r.parentNode.insertBefore(i,r);for(var s=function(){return this._q=[],this},l=["add","append","clearAll","prepend","set","setOnce","unset"],a=0;a<(null==l?void 0:l.length);a++)o(s,l[a]);n.Identify=s;for(var u=function(){return this._q=[],this},c=["setProductId","setQuantity","setPrice","setRevenueType","setEventProperties"],d=0;d<(null==c?void 0:c.length);d++)o(u,c[d]);n.Revenue=u;var p=["init","logEvent","logRevenue","setUserId","setUserProperties","setOptOut","setVersionName","setDomain","setDeviceId","enableTracking","setGlobalUserProperties","identify","clearUserProperties","setGroup","logRevenueV2","regenerateDeviceId","groupIdentify","onInit","logEventWithTimestamp","logEventWithGroups","setSessionId","resetSessionId"];function v(e){function t(t){e[t]=function(){e._q.push([t].concat(Array.prototype.slice.call(arguments,0)))}}for(var n=0;n<(null==p?void 0:p.length);n++)t(p[n])}v(n),n.getInstance=function(e){var t,i;return e=(e&&0!==(null===(t=e)||void 0===t?void 0:t.length)?e:"$default_instance").toLowerCase(),null!==(i=n._iq)&&void 0!==i&&i.hasOwnProperty(e)||(n._iq[e]={_q:[]},v(n._iq[e])),n._iq[e]},e.amplitude=n}(window,document);
             amplitude.getInstance().init("${AMPLITUDE_KEY}", null, {includeUtm: true,saveEvents: true});
            `}
          </script>
        </Helmet>
        <App />
      </BrowserRouter>
    </HelmetProvider>
  </React.StrictMode>,
  document.getElementById('root')
)

reportWebVitals()
