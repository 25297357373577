const options = ['Прошедшие', 'Будущие']
const wordsParticiple = ['проголосовавший', 'проголосовавших']
//TODO можно вообще объект сделать с ключом - опцией, а значением - past или future, соответственно
const typeOptions = (selectedOption: string) => {
  if (selectedOption === options[0]) return 'past'
  if (selectedOption === options[1]) return 'future'
}

export type TInitState = {
  webinarTitle: string
  startDate?: Date
  benchmarkWebinar?: null | number
  linkTitle: string
  Lecturs: { name: string }[]
  Specialities: { name: string }[]
  averageOnlineWatchTime: null | number
  averageTimeInMs: null | number
  onlineUsersCount: null | number
  regisrationsCount: null | number
  pageOpeningCount: null | number
  averageRating: null | number
  uniqueWatchCount: number | null
  votes: null | number
  eventCoverage: null | number
  extendedReport: null | string
  publicateWebinarAfterEnd: boolean
  fileStatistic: { fileName: string; path: string; textName: string }[]
  isLoading: boolean
}
const initState: TInitState = {
  webinarTitle: '',
  linkTitle: '',
  Lecturs: [],
  Specialities: [],
  averageOnlineWatchTime: null,
  averageTimeInMs: null,
  onlineUsersCount: null,
  regisrationsCount: null,
  pageOpeningCount: null,
  averageRating: null,
  uniqueWatchCount: null,
  votes: null,
  eventCoverage: null,
  extendedReport: null,
  fileStatistic: [],
  isLoading: true,
  publicateWebinarAfterEnd: true
}

export enum ActiveBlock {
  coverage = 'Охваты',
  report = 'Отчет по участникам',
  funnel = 'Воронка'
}

export { options, wordsParticiple, typeOptions, initState }
