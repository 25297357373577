const API_URL = '/pharmacy'
const AUTH_URL = '/auth'

const LOGIN_URL = `${AUTH_URL}/login`
const LOGOUT_URL = `${AUTH_URL}/logout`
const REFRESH_URL = `${AUTH_URL}/refresh`
const LK = `${API_URL}/api/user/lk`

const REFRESH_CALLDOWN = 15

const clientEndpoint = ''

export { clientEndpoint, API_URL, LOGIN_URL, LOGOUT_URL, REFRESH_URL, REFRESH_CALLDOWN, LK }
