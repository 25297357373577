const formatLectursName = (nameObjs: any[]) => {
  if (nameObjs?.length)
    return nameObjs
      .reduce((acc: string[], elem: { [key in string]: string }) => {
        const { lastName, firstName, secondName } = elem
        acc.push(`${lastName} ${firstName[0]}. ${secondName[0]}.`)
        return acc
      }, [])
      ?.join(', ')
  return ''
}

const formatSpecNames = (specs: { name: string }[]) => {
  if (specs?.length) {
    const formattedSpecs = specs.map((el) => el.name.toLowerCase()).join(', ')
    return `${formattedSpecs[0].toUpperCase()}${formattedSpecs.slice(1)}`
  }
  return ''
}

const getDurstion = (number: number) => {
  const minutes = Math.floor(number / 1000 / 60)
  const seconds = Math.floor((number - minutes * 1000 * 60) / 1000)
  return `${minutes} <span style='font-size: 16px;'>мин</span> ${seconds} <span style='font-size: 16px;'>сек</span>`
}

export { formatLectursName, formatSpecNames, getDurstion }
