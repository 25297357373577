import { IAnyObj } from 'src/constants/types'

declare global {
  interface Window {
    amplitude: any
  }
}

const setEvent = (event: string, properties?: IAnyObj): void => {
  try {
    window?.amplitude.getInstance().logEvent(event, properties)
  } catch (err) {
    console.warn('problem with setEvent amplitude', err)
  }
}

const setUserId = (userid: string) => {
  try {
    window?.amplitude.getInstance().setUserId(userid)
  } catch (err) {
    console.warn('problem with setUserId amplitude', err)
  }
}

export default {
  setEvent,
  setUserId
}
