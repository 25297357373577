import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import InputValidation from '../../input-validation-react/input-validation-react'

import { fieldData } from 'src/constants/input-data'

interface IProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  rules?: {
    required: string
    validate: (val: string) => string | undefined
  }
  touched: { [key: string]: boolean }
  modificator?: string
  name: string
}

const ControllerBaseInput: FC<IProps> = ({ control, errors, rules, touched, name, ...props }) => {
  return (
    <Controller
      control={control}
      name={name}
      rules={rules}
      render={({ field: { ref, ...rest } }) => {
        return (
          <InputValidation
            setInputRef={ref}
            touched={touched?.[name]}
            error={errors[name]}
            {...fieldData[name]}
            {...rest}
            {...props}
          />
        )
      }}
    />
  )
}

export default ControllerBaseInput
