const A_CLIENT_WEBINAR_FUTURE_OPEN = 'client_webinar_future_open'
const A_CLIENT_WEBINAR_PAST_OPEN = 'client_webinar_past_open'
const A_CLIENT_REPORT_DOWNLOAD = 'client_report_download'
const A_CLIENT_EXTENDED_REPORT = 'client_extended_report'
const A_CLIENT_CASE_CLICK = 'client_case_click'
const A_CLIENT_DISCOUNT_CLICK = 'client_discount_click'
const A_CLIENT_DISCOUNT_SENT = 'client_discount_sent'
const A_CLIENT_WANT_CLICK = 'client_want_click'
const A_CLIENT_WANT_SENT = 'client_want_sent'
const A_CLIENT_ARTICLE_OPEN = 'client_article_open'
const A_CLIENT_VIDEO_OPEN = 'client_video_open'

export {
  A_CLIENT_WEBINAR_FUTURE_OPEN,
  A_CLIENT_WEBINAR_PAST_OPEN,
  A_CLIENT_REPORT_DOWNLOAD,
  A_CLIENT_EXTENDED_REPORT,
  A_CLIENT_CASE_CLICK,
  A_CLIENT_DISCOUNT_CLICK,
  A_CLIENT_DISCOUNT_SENT,
  A_CLIENT_WANT_CLICK,
  A_CLIENT_WANT_SENT,
  A_CLIENT_ARTICLE_OPEN,
  A_CLIENT_VIDEO_OPEN
}
