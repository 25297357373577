const commonPlugins = {
  legend: {
    position: 'bottom' as const,
    align: 'start' as const,
    labels: {
      color: '#4D4F5C',
      font: {
        size: 14,
        family: 'Source Sans Pro'
      }
    }
  },
  datalabels: {
    display: false
  }
}

const labelPlugins = {
  ...commonPlugins,
  datalabels: {
    color: '#4D4F5C' as const,
    anchor: 'end' as const,
    align: 'end' as const,
    offset: 17,
    padding: 16,
    backgroundColor: 'white',
    borderRadius: 10,
    borderColor: '#EAF0F4',
    borderWidth: 1,
    font: {
      size: 12
    },
    formatter: function (value: any, context: any) {
      return `${value} зрит.`
    }
  }
}

export { commonPlugins, labelPlugins }
