import { FC, ReactElement } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import WhiteContainer from 'src/containers/white-container/white-container'

import icon from 'src/public/svg/linkInWidget.svg'
import styles from './infoWidget.module.scss'

interface IinfoWidget extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  topTxt: string
  middleTxt: string | number
  widgetLink?: string
  bottomTxt?: string
  isNewTab?: boolean
  modificator?: string
  widgetIcon?: boolean
  onClick?: () => void
  children?: string | ReactElement
}

const InfoWidget: FC<IinfoWidget> = ({
  topTxt,
  middleTxt,
  bottomTxt,
  widgetLink = './',
  isNewTab,
  widgetIcon,
  modificator,
  onClick,
  children
}) => (
  <div
    className={clsx(styles.infoWidget, {
      [modificator as string]: modificator
    })}
    onClick={onClick}
  >
    <Link to={widgetLink} target={isNewTab ? '_blank' : ''} rel="noopener noreferrer">
      <WhiteContainer>
        {widgetIcon && (
          <img
            className={styles.widgetIcon}
            src={icon}
            decoding="async"
            loading="lazy"
            alt="Иконка ссылки"
          />
        )}
        <div className={styles.widgetTopTxt}>{topTxt}</div>
        <div className={styles.widgetMiddleTxt}>{middleTxt}</div>
        <div className={styles.widgetBottomTxt}>{bottomTxt}</div>
        <div>{children}</div>
      </WhiteContainer>
    </Link>
  </div>
)
export default InfoWidget
