import { FC } from 'react'
import clsx from 'clsx'

import ValueWidget from 'src/components/parts/valueWidget/valueWidget'
import Graph from 'src/components/parts/graph/graph'
import GraphBars from 'src/components/parts/graph-bars/graph-bars'

import { getDurstion } from 'src/utils/prepareData'
import { numericToParticiple } from 'src/utils/numericToWord'
import { TInitState, wordsParticiple } from 'src/pages/webinar/data'

import common from 'src/styles/variables/common.module.scss'
import styles from './coverage-webinar.module.scss'

interface ICoverageWebinarProps {
  statData: TInitState
  id?: string
}

const CoverageWebinar: FC<ICoverageWebinarProps> = ({ statData, id }) => {
  const inflectedWord = statData.votes
    ? `${statData.votes} ${statData.votes && numericToParticiple(statData.votes, wordsParticiple)}`
    : null

  return (
    <>
      <h2 className={common.blockName}>Охваты</h2>
      {/* <p className={styles.dateTxt}>Данные на 20.04.2023 10:00</p> */}
      <div className={styles.topWidgetWrapper}>
        <ValueWidget
          title="Общий охват мероприятия"
          hint='Общий охват считается как сумма "Зрителей на вебинаре" и "Уникальных просмотров записи"'
          info={String(statData.eventCoverage)}
        />
        <ValueWidget
          title={inflectedWord ? `Оценка (${inflectedWord})` : 'Оценка'}
          info={String(statData.averageRating) !== 'null' ? `${statData.averageRating} <span style='font-size: 16px'>из</span> 5` : '-'}
        />
      </div>
      <div className={styles.graphWrapper}>
        <Graph id={id} />
      </div>
      <h2 className={common.blockSubName}>статистика по прямой трансляции</h2>
      <div className={common.valueWidgetWrapper}>
        <ValueWidget title="Регистраций на вебинар" info={String(statData.regisrationsCount)} />
        <ValueWidget
          title="Зрителей на вебинаре"
          info={String(statData.onlineUsersCount)}
          benchmark={statData.benchmarkWebinar}
          percentDiff={
            statData.benchmarkWebinar
              ? Math.round((Number(statData.onlineUsersCount) / statData.benchmarkWebinar) * 100)
              : null
          }
        />
        <ValueWidget
          title="Среднее время просмотра вебинара"
          info={statData.averageOnlineWatchTime ? getDurstion(statData.averageOnlineWatchTime) : null}
        />
      </div>
      {statData.publicateWebinarAfterEnd && (
        <>
          <h2 className={common.blockSubName}>статистика по видеозаписи</h2>
          <div className={clsx(common.valueWidgetWrapper, styles.marginBtmMod)}>
            <ValueWidget title="Открытий страницы с записью" info={String(statData.pageOpeningCount)} />
            <ValueWidget title="Уникальных просмотров записи" info={String(statData.uniqueWatchCount)} />
            <ValueWidget
              title="Среднее время просмотра записи"
              info={statData.averageTimeInMs ? getDurstion(statData.averageTimeInMs) : null}
            />
          </div>
        </>
      )}
      <h2 className={common.blockName}>Активность</h2>
      <GraphBars id={id} />
    </>
  )
}

export default CoverageWebinar
