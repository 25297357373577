import { FC, useEffect, useState } from 'react'
import styles from './bar.module.scss'
import colors from './bar-colors.module.scss'
import clsx from 'clsx'
import { calcPercent } from 'src/utils/calcPercent'
interface IBar {
  value: number
  maxValue: number
  color?: BarColors
  mod?: string
}

export enum BarColors {
  CYAN = 'cyan',
  BILBERRY = 'bilberry',
  GREEN = 'green',
  ORANGE = 'orange',
  PINK = 'pink',
  YELLOW = 'yellow'
}

const Bar: FC<IBar> = ({ value, maxValue, color, mod }) => {
  const [width, setWidth] = useState('0')
  const fixValue = value > maxValue ? maxValue : value
  const barWidth = `${calcPercent(fixValue, maxValue)}%`
  const barColor = color ? color : 'cyan'

  useEffect(() => setWidth(barWidth), [barWidth])

  return (
    <div className={clsx(styles.container, { [mod as string]: mod })}>
      <div className={clsx(styles.back, styles.bar_form)}></div>
      <div
        className={clsx(styles.bar, styles.bar_form, colors[barColor])}
        style={{ width: width }}
      ></div>
    </div>
  )
}
export default Bar
