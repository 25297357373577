import { FC, useEffect, useState } from 'react'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
} from 'chart.js'
import { Line } from 'react-chartjs-2'

import WhiteContainer from 'src/containers/white-container/white-container'
import Loader from 'src/components/parts/loader/loader'

import { commonPlugins } from 'src/constants/graph-data'
import styles from './graph.module.scss'
import axios from 'axios'
import { API_URL } from 'src/constants'

ChartJS.register(CategoryScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend)
// Не получается у лейбла сделать бордер радиус, как будто нельзя https://www.chartjs.org/docs/latest/configuration/legend.html. Попробовал все
const options = {
  responsive: true,
  plugins: commonPlugins
}

const data = {
  labels: [] as any[],
  datasets: [
    {
      label: 'Зрителей онлайн',
      data: [] as any[],
      borderColor: '#54D8FF',
      backgroundColor: '#54D8FF'
    }
  ]
}

const Graph: FC<{ id?: string | number }> = ({ id }) => {
  const [dataToGraph, setDataToGraph] = useState({ ...data, isReady: false } as any)
  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/webinarstat/getOnlineWatchStat?id=${id}`)
        const xData = res?.data?.x
        const yData = res?.data?.y

        if (xData && yData) {
          const updData = { ...dataToGraph }
          updData.labels = xData
          updData.datasets[0].data = yData
          updData.isReady = true
          setTimeout(() => {
            setDataToGraph(updData)
          }, 500)
        }
      } catch (err) {
        console.log(err)
      }
    }
    if (!id) return
    getData()
  }, [id])
  return dataToGraph.isReady ? (
    <WhiteContainer>
      <div className={styles.graphHeader}>
        <div className={styles.title}>Динамика подключений</div>
      </div>
      <Line options={options} data={dataToGraph} />
    </WhiteContainer>
  ) : (
    <WhiteContainer>
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    </WhiteContainer>
  )
}

export default Graph
