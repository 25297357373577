import { FC } from 'react'
import clsx from 'clsx'

import preloadIcon from 'src/public/svg/preload.svg'
import styles from './loader.module.scss'

interface ILoader {
  text?: string
  modificator?: string
}

const Loader: FC<ILoader> = ({ text, modificator }) => {
  return (
    <div className={styles.loadMessage}>
      <img
        src={preloadIcon}
        width={50}
        height={50}
        className={clsx(styles.img, { [modificator as string]: modificator })}
        alt="загрузка"
      />
      {text && <p className={styles.text}>{text}</p>}
    </div>
  )
}

export default Loader
