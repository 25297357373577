import GTag from './gtagMethods'
import AM from './amplitudeMethods'

import { IStringObj } from 'src/constants/types'

const setEvent = (event: string, data?: IStringObj) => {
  GTag.event(event, data)
  AM.setEvent(event, data)
}

export default {
  setEvent
}
