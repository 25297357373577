const options = {
  sale: {
    title: 'Хочу скидку',
    subtitle:
      'Оставьте свои контактные данные, чтобы мы смогли с вами связаться и обсудить условия предоставления скидки'
  },
  want: {
    title: 'Хочу также',
    subtitle: 'Оставьте свои контактные данные, чтобы мы смогли с вами связаться и обсудить условия'
  }
}

export { options }
