import { FC } from 'react'

import WhiteContainer from 'src/containers/white-container/white-container'
import ScrollArea from 'src/containers/scroll-area/scroll-area'
import Loader from 'src/components/parts/loader/loader'
import Button from '../button/button'
import ButtonThemes from '../button/themes'

import styles from './table.module.scss'

interface ITable {
  data: { _id: string; total: number; online?: number; record?: number }[] | []
  materialType: 'article' | 'video' | 'webinar'
  handleClick?: () => void
}

const Table: FC<ITable> = ({ data, materialType, handleClick }) => (
  <WhiteContainer modificator={styles.whiteConteiner}>
    <div className={styles.tabTitle}>
      <p className={styles.rowLeft}>Cпециальность</p>
      <p className={styles.rowRight}>Количество пользователей</p>
      {materialType === 'webinar' && (
        <>
          <p className={styles.rowRight}>Трансляция</p>
          <p className={styles.rowRight}>Запись</p>
        </>
      )}
    </div>
    <div className={styles.audienceTab}>
      <ScrollArea isNarrowScrollbar>
        {data?.length ? (
          data.map((elem) => (
            <div className={styles.row} key={elem._id}>
              <p className={styles.rowLeft}>{elem?._id}</p>
              <p className={styles.rowRight}>{elem?.total}</p>
              {materialType === 'webinar' && (
                <>
                  <p className={styles.rowRight}>{elem?.online}</p>
                  <p className={styles.rowRight}>{elem?.record || '➖'}</p>
                </>
              )}
            </div>
          ))
        ) : (
          <Loader />
        )}
      </ScrollArea>
    </div>
    <div className={styles.downloadBtnWrap}>
      <Button theme={ButtonThemes.DOWNLOAD_NOBG} onClick={handleClick}>
        Скачать таблицу
      </Button>
    </div>
  </WhiteContainer>
)
export default Table
