import { FC, useState } from 'react'
import { Link } from 'react-router-dom'

import WhiteContainer from 'src/containers/white-container/white-container'
import Button from 'src/components/parts/button/button'
import ButtonThemes from 'src/components/parts/button/themes'
import FeedBackPopup from 'src/components/parts/feedback-popup/feedback-popup'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import { A_CLIENT_CASE_CLICK, A_CLIENT_DISCOUNT_CLICK } from 'src/constants/analytic-data'
import { materialExample } from 'src/constants/links-data'
import { content } from './data'
import styles from 'src/styles/no-article-page/no-article-page.module.scss'

interface INoArticle {
  materialType: 'article' | 'video'
}

const NoArticle: FC<INoArticle> = ({ materialType }) => {
  const { userData } = useAuth()
  
  const [isShow, setShow] = useState(false)
  const toggleShow = () => setShow((value) => !value)

  const googleAnalyticCase = () => {
    A.setEvent(A_CLIENT_CASE_CLICK, { name: userData?.firstName })
  }
  const googleAnalyticBtn = () => {
    A.setEvent(A_CLIENT_DISCOUNT_CLICK, { name: userData?.firstName })
  }

  return (
    <>
      <p className={styles.headerPage}>{content[materialType].title}</p>
      <div className={styles.caseWrapper}>
        <WhiteContainer modificator={styles.whiteContainer}>
          <p className={styles.caseTitle}>{content[materialType].titleCase}</p>
          <ul>
            {content[materialType].pointsCase?.map((elem, index) => (
              <li className={styles.caseLi} key={index}>
                {elem}
              </li>
            ))}
          </ul>
          <Link to={`${materialExample[materialType]}`} onClick={googleAnalyticCase}>
            <Button theme={ButtonThemes.WHITE} modificator={styles.btnCase}>
              {content[materialType].btnCase}
            </Button>
          </Link>
        </WhiteContainer>
      </div>
      <div className={styles.saleWrapper}>
        <WhiteContainer modificator={styles.whiteContainer}>
          {content[materialType].titleSale && (
            <p className={styles.caseTitle}>{content[materialType].titleSale}</p>
          )}
          <p className={styles.caseTxtBold}>{content[materialType].subtitleSale}</p>
          <div onClick={googleAnalyticBtn}>
            <Button modificator={styles.btnSale} onClick={toggleShow}>
              Хочу скидку
            </Button>
          </div>
        </WhiteContainer>
      </div>
      <FeedBackPopup active={isShow} handlePopup={toggleShow} feedback={'sale'}></FeedBackPopup>
    </>
  )
}

export default NoArticle
