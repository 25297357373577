import { useState } from 'react'
import { Helmet } from 'react-helmet-async'
import clsx from 'clsx'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import ValueWidget from 'src/components/parts/valueWidget/valueWidget'
import Button from 'src/components/parts/button/button'
import GoBack from 'src/components/parts/go-back/go-back'
import FeedBackPopup from 'src/components/parts/feedback-popup/feedback-popup'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import { A_CLIENT_WANT_CLICK } from 'src/constants/analytic-data'
import { content } from 'src/constants/article-example.data'
import { goBackInfo } from 'src/constants/links-data'

import common from 'src/styles/variables/common.module.scss'
import styles from 'src/styles/article-example/article-example.module.scss'

const ArticleExample = (props: { materialType: 'article' | 'video' }) => {
  const [isShow, setShow] = useState(false)
  const toggleShow = () => setShow((value) => !value)
  const { userData } = useAuth()

  const googleAnalyticBtn = () => {
    A.setEvent(A_CLIENT_WANT_CLICK, { name: userData?.firstName })
  }

  const { materialType } = props

  return (
    <>
      <Helmet>
        <title>{content[materialType].helmet}</title>
      </Helmet>
      <WidthLimiter>
        <GoBack link={goBackInfo[materialType].link} txt={goBackInfo[materialType].txt}></GoBack>
        <p className={common.headerPage}>{content[materialType].title}</p>
        <div className={styles.categoryWrapper}>
          <p className={clsx(styles.category, common.greyLightTxt)}>ссылка на статью</p>
          <a className={styles.link} href={content[materialType].titleLink}>
            {content[materialType].titleLink}
          </a>
        </div>
        <div className={clsx(styles.categoryWrapper, common.blackTxt)}>
          <p className={clsx(styles.category, common.greyLightTxt)}>задача статьи</p>
          <p>{content[materialType].task}</p>
        </div>
        <div className={clsx(styles.categoryWrapper, common.blackTxt)}>
          <p className={clsx(styles.category, common.greyLightTxt)}>целевая аудитория</p>
          <p>{content[materialType].audience}</p>
        </div>
        <div className={styles.valueWrapper}>
          <ValueWidget
            modificator={styles.valueWidget}
            title={content[materialType].firstWidget.title}
            info={String(content[materialType].firstWidget.value)}
          />
          <ValueWidget
            title={content[materialType].secondWidget.title}
            info={String(content[materialType].secondWidget.value)}
          />
        </div>
        {content[materialType].txtBottom && (
          <p className={clsx(styles.bottomTxt, common.blackBoldTxt)}>
            {content[materialType].txtBottom}
          </p>
        )}
        <div onClick={googleAnalyticBtn}>
          <Button modificator={styles.btn} onClick={toggleShow}>
            Хочу также
          </Button>
        </div>
        <FeedBackPopup active={isShow} handlePopup={toggleShow} feedback="want"></FeedBackPopup>
      </WidthLimiter>
    </>
  )
}

export default ArticleExample
