import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

const createExcel = async ({
  data,
  titles,
  isWebinar,
  id
}: {
  data: any[]
  titles: string[]
  isWebinar: boolean
  id: number
}) => {
  const dataForExcel = [titles]

  const formatData = () => {
    data.forEach((item) => {
      if (isWebinar) {
        const itemReverse = Object.values(item).reverse()
        dataForExcel.push(itemReverse as string[])
      } else {
        dataForExcel.push(Object.values(item))
      }
    })
  }
  formatData()

  const ws = XLSX.utils.aoa_to_sheet(dataForExcel)
  if (isWebinar) {
    ws['!cols'] = [{ wch: 50 }, { wch: 10 }, { wch: 20 }, { wch: 20 }]
  } else {
    ws['!cols'] = [{ wch: 50 }, { wch: 50 }]
  }

  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: { Sheet1: ws }
  }

  saveAs(
    new Blob([XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' })], {
      type: 'application/octet-stream'
    }),
    `users_data_${id}.xlsx`
  )
}

export { createExcel }
