import { FC } from 'react'
import clsx from 'clsx'

import WhiteContainer from 'src/containers/white-container/white-container'

import { ReactComponent as Icon } from 'src/public/svg/error_icon.svg'
import styles from './warning.module.scss'

interface IProps {
  txt?: string
  modificator?: string
}

const Warning: FC<IProps> = ({ txt, modificator }) => (
  <WhiteContainer modificator={clsx(styles.whiteConteiner, modificator)}>
    <div className={styles.warning}>
      <div className={styles.warningIcon}>
        <div>
          <Icon></Icon>
        </div>
      </div>
      <span className={styles.warningTxt}>
        {txt ||
          'Количество пользователей в таблице может быть больше общего охвата мероприятия, т.к. у врача может быть несколько специальностей'}
      </span>
    </div>
  </WhiteContainer>
)
export default Warning
