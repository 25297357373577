import { FC, useState } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import { Link, Outlet } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'
import axios from 'axios'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import ControllerBaseInput from 'src/components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import ControllerTextareaInput from 'src/components/form-parts/controller-hook-forms/controller-textarea-input/controller-textarea-input'
import Button from 'src/components/parts/button/button'

import { useAuth } from 'src/hooks/AuthProvider'
import { FieldsNameVoc, firstNameValidation, emailValidation } from 'src/constants/input-data'
import { alertError, alertSend } from 'src/utils/alert'
import { API_URL, clientEndpoint } from 'src/constants'

import styles from 'src/styles/found-error-page/found-error-page.module.scss'

let disabledBtn = false

const FoundBug: FC = () => {
  const { userData } = useAuth()
  const {
    handleSubmit,
    control,
    formState: { dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      email: '',
      textarea: ''
    }
  })
  const { errors, touchedFields, isValid } = useFormState({ control })

  const [isSend, setSend] = useState(false)

  const onSubmitForm = async (data: any) => {
    disabledBtn = true
    alertSend()
    try {
      const res = await axios.post(`${API_URL}/api/sendform/sendRequestForm`, {
        sponsorName: userData.firstName,
        ...data,
        origin: 'bug'
      })
      if (res.status) {
        disabledBtn = false
        setSend(true)
      }
    } catch {
      alertError()
    }
  }

  const isDisableSubmit = () => {
    if (isValid && Object.keys(dirtyFields).length > 0 && !disabledBtn) return false
    return true
  }

  return (
    <>
      <WidthLimiter>
        <Helmet>
          <title>Нашли Ошибку?</title>
        </Helmet>
        <p className={styles.headerPage}>Нашли ошибку?</p>
        {!isSend ? (
          <>
            <form id="form" onSubmit={handleSubmit(onSubmitForm)}>
              <div className={styles.inputWrapper}>
                <ControllerBaseInput
                  control={control}
                  name={FieldsNameVoc.firstName}
                  touched={touchedFields}
                  errors={errors}
                  rules={firstNameValidation}
                />
                <ControllerBaseInput
                  control={control}
                  name={FieldsNameVoc.email}
                  errors={errors}
                  touched={touchedFields}
                  rules={emailValidation}
                />
              </div>
              <ControllerTextareaInput
                control={control}
                name="textarea"
                nameLabel="Опишите ошибку"
                errors={errors}
                touched={touchedFields}
                rules={firstNameValidation}
                modificator={styles.textarea}
              />

              <div className={styles.buttonWrap}>
                <Button type="submit" disabled={isDisableSubmit()}>
                  Отправить
                </Button>
              </div>
              <ToastContainer />
            </form>
          </>
        ) : (
          <>
            <div>
              Благодарим за обратную связь! <br />В течение 24 часов рассмотрим вашу заявку и вернемся с ответом по
              указанному email.
            </div>
            <div className={styles.webinarBtn}>
              <Link to={`${clientEndpoint}/webinar`}>
                <Button>К вебинарам</Button>
              </Link>
            </div>
          </>
        )}
      </WidthLimiter>
      <Outlet />
    </>
  )
}

export default FoundBug
