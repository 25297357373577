import { FC } from 'react'
import { useNavigate } from 'react-router-dom'
import { useForm, useFormState } from 'react-hook-form'
import { Helmet } from 'react-helmet-async'
import { ToastContainer } from 'react-toastify'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import ControllerBaseInput from 'src/components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'
import Button from 'src/components/parts/button/button'

import { useAuth } from 'src/hooks/AuthProvider'
import { passwordValidation, emailValidation, FieldsNameVoc } from 'src/constants/input-data'
import { alertSend } from 'src/utils/alert'

import formStyles from 'src/styles/found-error-page/found-error-page.module.scss'
import styles from 'src/styles/auth/auth.module.scss'

let disabledBtn = false

const Auth: FC = () => {
  const navigate = useNavigate()
  const { userData, loginAction, logOut } = useAuth()

  const {
    handleSubmit,
    control,
    formState: { dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      email: '',
      password: ''
    }
  })
  const { errors, touchedFields, isValid } = useFormState({ control })

  const onSubmitForm = async (data: any) => {
    disabledBtn = true
    alertSend()
    try {
      await loginAction(data)
    } catch (err) {
      console.log('error in onSubmitForm')
    }
    disabledBtn = false
  }

  const isDisableSubmit = () => {
    if (isValid && Object.keys(dirtyFields).length > 0 && !disabledBtn) return false
    return true
  }

  const logout = async () => {
    try {
      await logOut()
    } catch (err) {
      console.log(err)
    }
  }

  return (
    <>
      <WidthLimiter>
        <Helmet>
          <title>Авторизация</title>
        </Helmet>
        {!userData ? (
          <form id="form" className={styles.loggedInWrapper} onSubmit={handleSubmit(onSubmitForm)}>
            <div className={styles.inputWrapper}>
              <ControllerBaseInput
                control={control}
                name={FieldsNameVoc.email}
                touched={touchedFields}
                errors={errors}
                rules={emailValidation}
              />
              <ControllerBaseInput
                control={control}
                name={FieldsNameVoc.password}
                errors={errors}
                touched={touchedFields}
                rules={passwordValidation}
              />
            </div>
            <div className={formStyles.buttonWrap}>
              <Button type="submit" disabled={isDisableSubmit()}>
                Отправить
              </Button>
            </div>
            <ToastContainer />
          </form>
        ) : (
          <div className={styles.loggedOutWrapper}>
            <div>Вы успешно авторизованы!</div>
            <div>
              Имя пользователя: <b>{userData.firstName}</b>
            </div>
            <div className={formStyles.buttonWrap} onClick={logout}>
              <Button>Выйти</Button>
            </div>
          </div>
        )}
      </WidthLimiter>
    </>
  )
}

export default Auth
