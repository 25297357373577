import { FC } from 'react'
import clsx from 'clsx'

import styles from './textarea-validation-react.module.scss'

interface IFormikTextarea {
  onChange: any
  error?: any
  value: string
  touched?: boolean | undefined
  nameLabel?: string
  modificator?: string
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  inputWrapperModificator?: string
}

const ValidationTextarea: FC<IFormikTextarea> = ({
  onChange,
  modificator,
  value,
  error,
  touched,
  nameLabel,
  labelModificator,
  inputNameModificator,
  innerContainerModificator,
  inputWrapperModificator,
  ...props
}) => {
  return (
    <div
      className={clsx(styles.innerContainer, {
        [innerContainerModificator as string]: innerContainerModificator
      })}
    >
      <label
        className={clsx(styles.labelInput, {
          [labelModificator as string]: labelModificator
        })}
      >
        <p
          className={clsx(styles.outerName, {
            [inputNameModificator as string]: inputNameModificator
          })}
        >
          {nameLabel}
        </p>

        <div
          className={clsx(styles.inputWrapper, {
            [inputWrapperModificator as string]: inputWrapperModificator
          })}
        >
          <textarea
            onChange={onChange}
            value={value}
            placeholder="Введите текст"
            {...props}
            className={clsx(styles.textarea, {
              [modificator as string]: modificator
            })}
          ></textarea>
          {touched && error?.message ? (
            <div>
              <p
                className={clsx(styles.errorCheck, {
                })}
              >
                {error?.message}
              </p>
            </div>
          ) : null}
        </div>
      </label>
    </div>
  )
}

export default ValidationTextarea
