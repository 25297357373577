import { FC, useRef, useState, useEffect } from 'react'
import clsx from 'clsx'

import style from './switch-toggle.module.scss'

interface ISwitchToggle {
  options: string[]
  selectedOption: string
  handleToggle: any
  modificator?: string
  isWithoutFade?: boolean
}

const SwitchToggle: FC<ISwitchToggle> = ({
  options,
  selectedOption,
  handleToggle,
  modificator,
  isWithoutFade = false
}) => {
  const [config, setConfig] = useState({
    width: 0,
    leftShift: 0,
    leftBoundary: true,
    rightBoundary: isWithoutFade
  })
  const selectedPositionRef = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    const position = selectedPositionRef?.current
    if (position) {
      setConfig({
        width: position.offsetWidth,
        leftShift: position.offsetLeft,
        leftBoundary: true,
        rightBoundary: isWithoutFade
      })
    }
  }, [selectedOption])

  const underlineStyle = {
    width: `${config.width}px`,
    transform: `translate3d(${config.leftShift}px, 0, 0)`
  }

  return (
    <div
      className={clsx(style.wrapperFade, {
        [style.wrapperNoRightFade]: config.rightBoundary,
        [style.wrapperNoLeftFade]: config.leftBoundary
      })}
    >
      <div className={clsx(style.toggle, {})}>
        <div className={clsx(style.underline, {})} style={underlineStyle} />
        {options.map((option) => {
          const isActive = option === selectedOption
          return (
            <button
              type="button"
              key={option}
              className={clsx(style.option, {
                [style.optionActive]: isActive,
                [modificator as string]: modificator
              })}
              ref={isActive ? selectedPositionRef : null}
              onClick={() => handleToggle(option)}
            >
              <p>{option}</p>
            </button>
          )
        })}
      </div>
    </div>
  )
}

export default SwitchToggle
