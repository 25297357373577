import { useContext, createContext, useState, useEffect, useRef } from 'react'
import { useLocation, useNavigate, redirect } from 'react-router-dom'
import axios from 'axios'

import { addMinutes } from 'src/utils/date/time'
import { alertAuthError, dismissAll } from 'src/utils/alert'
import { LOGIN_URL, LOGOUT_URL, REFRESH_URL, REFRESH_CALLDOWN, LK, clientEndpoint } from 'src/constants'

const AuthContext = createContext()

const AuthProvider = ({ children }) => {
  const navigate = useNavigate()
  const location = useLocation()
  const refreshTimer = useRef(null)

  const [isAuth, setAuth] = useState('pending')
  const [user, setUser] = useState(null)

  const clearTimer = () => {
    const timer = refreshTimer.current
    if (timer) {
      clearTimeout(timer)
    }
  }

  const loginAction = async (data) => {
    try {
      const {
        data: { code, expireTime, accessToken }
      } = await axios.post(LOGIN_URL, data)

      if (code === 200) {
        setAuth('auth')
        axios.defaults.headers.common = {
          Authorization: `Bearer ${accessToken}`
        }
      }
    } catch (err) {
      console.error(err)
      dismissAll()
      alertAuthError()
      throw new Error(err)
    }
  }

  const logOut = async () => {
    try {
      const res = await axios.post(LOGOUT_URL, {})
      if (res.status === 200) {
        setUser(null)
        setAuth('unauth')
        clearTimer()
        axios.defaults.headers.common = { Authorization: '' }
        navigate(`${clientEndpoint}/login`)
      }
    } catch (err) {
      console.log(err)
    }
  }

  useEffect(() => {
    const refreshToken = async () => {
      setAuth('pending')
      clearTimer()
      try {
        const res = await axios.get(REFRESH_URL)
        const {
          data: { accessToken, expireTime }
        } = res
        axios.defaults.headers.common = {
          Authorization: `Bearer ${accessToken}`
        }
        if (isAuth !== 'auth') setAuth('auth')
        const currentTime = new Date()
        const refreshTime = addMinutes({ currDate: currentTime, mins: REFRESH_CALLDOWN })
        const leftTime = refreshTime.getTime() - currentTime.getTime()

        refreshTimer.current = setTimeout(() => {
          refreshToken()
        }, leftTime)
      } catch (err) {
        console.log(err)
        setAuth('unauth')
        setUser(null)
        if (err.response.status === 403) {
          sessionStorage.removeItem('deathTime')
          navigate(`${clientEndpoint}/login`)
        } else if (err.response.status !== 200) {
          console.log('ERROR', err.response)
          alertAuthError()
        }
      }
    }

    refreshToken()

    return clearTimer
  }, [])

  useEffect(() => {
    if (isAuth === 'auth' && !user) {
      const getData = async () => {
        const res = await axios.get(LK)
        if (res.status === 200) {
          const profile = res.data
          setUser(profile)
          if (location.pathname === '/login') navigate(`${clientEndpoint}/webinar`)
        }
      }
      getData()
    }
  }, [isAuth])

  return <AuthContext.Provider value={{ isAuth, userData: user, loginAction, logOut }}>{children}</AuthContext.Provider>
}

export default AuthProvider

export const useAuth = () => {
  return useContext(AuthContext)
}
