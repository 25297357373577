import { FC, ReactElement } from 'react'
import clsx from 'clsx'
import ButtonThemes from './themes'
import styles from './button.module.scss'

interface IButton extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  theme?: ButtonThemes
  modificator?: string
  children: string | ReactElement
}

const Button: FC<IButton> = ({ theme, modificator, children, ...props }) => (
  <button
    className={clsx(styles.button, {
      [styles.buttonDownload]: theme === ButtonThemes.DOWNLOAD,
      [styles.noBg]: theme === ButtonThemes.NOBG,
      [styles.downloadNoBg]: theme === ButtonThemes.DOWNLOAD_NOBG,
      [modificator as string]: modificator
    })}
    {...props}
  >
    {children}
  </button>
)
export default Button
