import { FC } from 'react'
import { useLocation } from 'react-router-dom'

import { useAuth } from 'src/hooks/AuthProvider'
import MenuItem, { MenuItemText } from './menu-item/menu-item'

import { navBarTopItems, logo } from './data'
import { ReactComponent as LogoutIcon } from 'src/public/svg/logout.svg'
import styles from './navbar.module.scss'

const Navbar: FC = () => {
  const location = useLocation()
  const { userData, logOut } = useAuth()

  return (
    <ul className={styles.navbar}>
      <div className={styles.navbarLogo}>
        <img src={logo} decoding="async" loading="lazy" alt="Лого" height={24} width={24} />
        {userData && (
          <>
            <div className={styles.verticalLine}></div>
            <div className={styles.userName}>{userData.firstName}</div>
          </>
        )}
      </div>
      <div className={styles.liWrapper}>
        <div className={styles.navbarTop}>
          {navBarTopItems.map((menuItem) => (
            <MenuItem key={menuItem.text} {...menuItem} page={location.pathname} />
          ))}
        </div>
        <div className={styles.navbarBottom} onClick={logOut}>
          <MenuItemText Icon={<LogoutIcon />} text="Выйти из аккаунта" />
        </div>
      </div>
    </ul>
  )
}
export default Navbar
