import { Dispatch, FC, SetStateAction } from 'react'
import clsx from 'clsx'
import Skeleton from 'react-loading-skeleton'

import FullWidthBG from 'src/containers/width-fullbg-limiter/width-fullbg-limiter'
import GoBack from 'src/components/parts/go-back/go-back'

import { formatSpecNames, formatLectursName } from 'src/utils/prepareData'
import { formatDate } from 'src/utils/dateFormatter'

import styles from './header-current-page.module.scss'
import { ActiveBlock } from 'src/pages/webinar/data'

interface IProps {
  goBack: string
  linkTitle: string
  txt: string
  title: string
  publicationDate?: string | null
  audience: { name: string }[]
  lecturs?: any[]
  changeBlock: Dispatch<SetStateAction<ActiveBlock>>
  activeBlock: ActiveBlock
}

const navBlocks = [
  { block: ActiveBlock.coverage },
  { block: ActiveBlock.report },
  { block: ActiveBlock.funnel }
]

const HeaderCurrentPage: FC<IProps> = ({
  goBack,
  linkTitle,
  txt,
  title,
  publicationDate,
  audience,
  lecturs,
  changeBlock,
  activeBlock
}) => {
  return (
    <FullWidthBG>
      <GoBack link={goBack} txt={txt} />
      <a className={styles.link} href={linkTitle} target="_blank" rel="noreferrer">
        <h1 className={styles.title}>{title || <Skeleton count={2} />}</h1>
      </a>
      {!publicationDate && !lecturs && (
        <p className={styles.date}>
          <Skeleton count={1} />
        </p>
      )}
      {publicationDate && (
        <p className={styles.date}>
          Дата публикации&nbsp;
          {formatDate(new Date(publicationDate))}
        </p>
      )}
      {lecturs && (
        <p className={styles.date}>{formatLectursName(lecturs) || <Skeleton count={1} />}</p>
      )}
      <p className={styles.audienceTitle}>Целевая аудитория</p>
      <p className={styles.audience}>{formatSpecNames(audience) || <Skeleton count={1} />}</p>
      <div className={styles.btnBlocksWrapper}>
        {navBlocks.map((item) => {
          return (
            <button
              key={item.block}
              className={clsx(styles.btnNav, {
                [styles.btnNavActive]: activeBlock === item.block
              })}
              onClick={() => changeBlock(item.block)}
            >
              {item.block}
            </button>
          )
        })}
      </div>
    </FullWidthBG>
  )
}

export default HeaderCurrentPage
