import { Helmet } from 'react-helmet-async'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'

import styles from 'src/styles/error.module.scss'

const NotFound = () => {
  return (
    <>
      <Helmet>
        <title>404</title>
      </Helmet>
      <WidthLimiter>
        <div className={styles.container}>
          <header className={styles.wrapper}>
            <h2 className={styles.title}>404</h2>
            <div className={styles.text}>
              <p>Ошибка. Страница не существует</p>
              <a className={styles.link} href="/">
                Вернуться на главную
              </a>
            </div>
          </header>
        </div>
      </WidthLimiter>
    </>
  )
}

export default NotFound
