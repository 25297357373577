const MOSCOW_TIMEZONE_OFFSET = 3

const prepareTime = (time: Date) => {
  const hours = time.getUTCHours() + MOSCOW_TIMEZONE_OFFSET
  let minutes: string | number = time.getMinutes()
  if (minutes < 10) minutes = `0${minutes}`
  return `${hours}:${minutes}`
}

const formatDate = (date: Date) => {
  const preparedTime = prepareTime(date)
  const day = date.getUTCDate()
  const month = date.getUTCMonth() + 1
  const preparedDate = `${day < 10 ? '0' + String(day) : day}.${
    month < 10 ? '0' + String(month) : month
  }.${date.getUTCFullYear()}`
  return `${preparedDate} ${preparedTime}`
}

export { prepareTime, formatDate }
