import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import { Outlet } from 'react-router-dom'
import axios from 'axios'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import InfoWidget from 'src/components/parts/infoWidget/infoWidget'
import NoArticle from 'src/components/no-article'
import Loader from 'src/components/parts/loader/loader'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import { A_CLIENT_ARTICLE_OPEN, A_CLIENT_VIDEO_OPEN } from 'src/constants/analytic-data'
import { formatDate } from 'src/utils/dateFormatter'
import { content } from './data'
import { IList } from 'src/constants/types'
import { API_URL } from 'src/constants'
import common from 'src/styles/variables/common.module.scss'
import styles from 'src/styles/article-page/article-page.module.scss'

let STOP_FETCH = false

const ArticleList = (props: { materialType: 'article' | 'video' }) => {
  const { materialType } = props
  const { userData } = useAuth()

  const [list, setList] = useState<IList[]>([])
  const [isLoading, setIsLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [subString, setSubString] = useState('')

  const containerRef = useRef<HTMLDivElement>(null)

  const handleChange = (event: any) => setSubString(event.target.value)

  const getData = async ({
    limit,
    offset,
    searchSubString
  }: {
    limit?: number
    offset?: number
    searchSubString?: string
  }) => {
    try {
      const res = await axios.get(
        `${API_URL}/api/articlestat/getArticleList?limit=${limit}&offset=${offset}&sponsorId=${userData.personalId}&isAdmin=${Boolean(userData.isAdmin)}&materialType=${materialType}&searchSubString=${searchSubString}`
      )

      if (res.data?.length === 0) STOP_FETCH = true
      setList([...list, ...res.data])
      setIsLoading(false)
      setLoadMore(false)
    } catch (err) {
      console.log(err)
    }
  }
  const googleAnalyticHandler = () => {
    if (materialType === 'article') {
      return A.setEvent(A_CLIENT_ARTICLE_OPEN, { name: userData?.firstName })
    } else {
      return A.setEvent(A_CLIENT_VIDEO_OPEN, { name: userData?.firstName })
    }
  }

  const handleScrollLimit = () => {
    if (!STOP_FETCH) {
      setLoadMore(true)
      getData({ limit: 10, offset: list.length, searchSubString: subString })
    }
  }

  useInfiniteScroll(containerRef, list, handleScrollLimit)

  //Перерендер на изменение пути
  useEffect(() => {
    setList([])
    setIsLoading(true)
    STOP_FETCH = false
  }, [materialType, subString])

  useEffect(() => {
    if (!userData?.isHasAccess || !materialType || !isLoading) return

    getData({ limit: 10, offset: list.length, searchSubString: subString })
  }, [isLoading, userData])

  return (
    <>
      <Helmet>
        <title>{content[materialType].helmet}</title>
      </Helmet>
      <WidthLimiter>
        <div className={styles.headerWrapper}>
          <p className={common.headerPage}>{content[materialType].title}</p>
          {userData?.isAdmin && (
            <input value={subString} className={styles.searchField} type="text" onChange={handleChange} />
          )}
        </div>
        {list.length ? (
          <div className={common.postWrapper} ref={containerRef}>
            {list.map((article: IList) => {
              return (
                <InfoWidget
                  key={article._id}
                  topTxt={formatDate(new Date(article.startDate))}
                  middleTxt={article.name}
                  widgetLink={`./${article.id}`}
                  onClick={googleAnalyticHandler}
                />
              )
            })}
            {loadMore && (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            )}
          </div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <NoArticle materialType={materialType} />
        )}
      </WidthLimiter>
      <Outlet />
    </>
  )
}

export default ArticleList
