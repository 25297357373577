import { useRef, RefObject } from 'react'
import useIsomorphicLayoutEffect from './useIsomorphicLayoutEffect'

const useInfiniteScroll = (
  containerRef: RefObject<HTMLDivElement>,
  dependencies: any,
  callback: () => void
): void => {
  const isHandleUsed = useRef(false)

  useIsomorphicLayoutEffect(() => {
    const element = containerRef?.current
    if (element) {
      const windowScroll = () => {
        const elementBottom = element.getBoundingClientRect().bottom
        const trigger = window.innerHeight * 1.1
        if (trigger >= elementBottom && !isHandleUsed.current) {
          isHandleUsed.current = true
          callback()
        }
      }

      isHandleUsed.current = false
      window.addEventListener('scroll', windowScroll)
      return () => window.removeEventListener('scroll', windowScroll)
    }
  }, [dependencies])
}

export default useInfiniteScroll
