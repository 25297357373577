import { FC, useState, useEffect } from 'react'
import { useForm, useFormState } from 'react-hook-form'
import clsx from 'clsx'
import axios from 'axios'
import { ToastContainer } from 'react-toastify'

import PopupWrapper from 'src/components/parts/popup-wrapper/popup-wrapper'
import Button from 'src/components/parts/button/button'
import ControllerBaseInput from 'src/components/form-parts/controller-hook-forms/controller-base-input/controller-base-input'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import { A_CLIENT_DISCOUNT_SENT, A_CLIENT_WANT_SENT } from 'src/constants/analytic-data'
import { FieldsNameVoc, firstNameValidation, emailValidation } from 'src/constants/input-data'
import { options } from './data'
import { alertError, alertSend } from 'src/utils/alert'
import { API_URL } from 'src/constants'

import common from 'src/styles/variables/common.module.scss'
import styles from './feedback-popup.module.scss'

interface IProps {
  active: boolean
  feedback: string
  handlePopup: (e: React.MouseEvent<HTMLDivElement>) => void
}

let disabledBtn = false

const FeedBackPopup: FC<IProps> = ({ active, feedback, handlePopup }) => {
  const [isSend, setSend] = useState(false)

  const {
    handleSubmit,
    control,
    reset,
    formState: { dirtyFields }
  } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName: '',
      email: ''
    }
  })

  const { errors, touchedFields, isValid } = useFormState({ control })
  const { userData } = useAuth()

  const googleAnalyticHandler = () => {
    if (feedback === 'sale') {
      A.setEvent(A_CLIENT_DISCOUNT_SENT, { name: userData?.firstName })
    } else {
      A.setEvent(A_CLIENT_WANT_SENT, { name: userData?.firstName })
    }
  }

  const isDisableSubmit = () => {
    if (isValid && Object.keys(dirtyFields).length > 0 && !disabledBtn) return false
    return true
  }

  const onSubmitForm = async (data: any) => {
    disabledBtn = true
    alertSend()
    try {
      const res = await axios.post(`${API_URL}/api/sendform/sendRequestForm`, {
        sponsorName: userData.firstName,
        ...data,
        feedback,
        origin: 'feedback'
      })

      if (res.status) {
        disabledBtn = false
        setSend(true)
      }
    } catch {
      alertError()
    }
  }
  useEffect(() => {
    if (active) {
      reset({
        firstName: '',
        email: ''
      })
    }
  }, [active])

  return (
    <PopupWrapper active={active} handlePopup={handlePopup}>
      {!isSend ? (
        <div>
          <p className={clsx(common.blackBoldTxt, styles.title)}>{options[feedback as keyof typeof options].title}</p>
          <p className={clsx(common.greyLightTxt, styles.subtitle)}>
            {options[feedback as keyof typeof options].subtitle}
          </p>
          <form id="form" onSubmit={handleSubmit(onSubmitForm)}>
            <div className={styles.inputWrap}>
              <ControllerBaseInput
                control={control}
                name={FieldsNameVoc.firstName}
                touched={touchedFields}
                errors={errors}
                rules={firstNameValidation}
              />
            </div>
            <div className={styles.inputWrap}>
              <ControllerBaseInput
                control={control}
                name={FieldsNameVoc.email}
                errors={errors}
                touched={touchedFields}
                rules={emailValidation}
              />
            </div>
            <div className={styles.buttonWrap} onClick={googleAnalyticHandler}>
              <Button type="submit" disabled={isDisableSubmit()}>
                Отправить
              </Button>
            </div>
            <ToastContainer />
          </form>
        </div>
      ) : (
        <>
          <div>
            Благодарим за обратную связь! <br />В течение 24 часов рассмотрим вашу заявку и вернемся с ответом по
            указанному email.
          </div>
          <div className={styles.btn} onClick={handlePopup}>
            <Button>Закрыть</Button>
          </div>
        </>
      )}
    </PopupWrapper>
  )
}
export default FeedBackPopup
