import { FC } from 'react'
import { Controller, Control, FieldErrors } from 'react-hook-form'

import TextareaValidation from '../../textarea-validation-react/textarea-validation-react'
import { texValidation } from 'src/constants/input-data'

interface IProps {
  control: Control<any, any>
  errors: FieldErrors<any>
  modificator?: string
  rules?: any
  touched: { [key: string]: boolean }
  name: string
  nameLabel: string
}

const ControllerTextareaInput: FC<IProps> = ({ control, name, errors, touched, ...props }) => {
  return (
    <Controller
      name={name}
      render={({ field }) => {
        // exclude ref to avoid React ref-passing warning
        const { ref, onChange, value, ...nonRefField } = field
        return (
          <TextareaValidation
            onChange={onChange}
            touched={touched?.[name]}
            error={errors[name]}
            value={value}
            {...nonRefField}
            {...props}
          />
        )
      }}
      rules={texValidation}
      control={control}
    />
  )
}

export default ControllerTextareaInput
