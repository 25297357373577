import axios from 'axios'
import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import { API_URL } from 'src/constants'

const colWidths = [
  { wch: 10 },
  { wch: 40 },
  { wch: 20 },
  { wch: 20 },
  { wch: 30 },
  { wch: 20 },
  { wch: 20 }
]

const downloadResults = async ({
  id,
  date,
  name
}: {
  id: number
  date?: Date
  name: string
}) => {
  const data = await axios.get(`${API_URL}/api/webinarstat/prepareDataForExcel?id=${id}`)
  const formattedDate = new Intl.DateTimeFormat('ru-RU').format(new Date(date || 0))
  const ws = XLSX.utils.json_to_sheet([])
  XLSX.utils.sheet_add_aoa(ws, [[`${name}; дата проведения ${formattedDate}`]])
  XLSX.utils.sheet_add_json(ws, data?.data, { origin: 'A2', skipHeader: true })

  ws['!cols'] = colWidths
  const workbook = {
    SheetNames: ['Sheet1'],
    Sheets: { Sheet1: ws }
  }

  saveAs(
    new Blob([XLSX.write(workbook, { bookType: 'xlsx', bookSST: false, type: 'array' })], {
      type: 'application/octet-stream'
    }),
    `${formattedDate}_${id}.xlsx`
  )
}

export { downloadResults }
