import { FC, useEffect, useState } from 'react'
import { Bar } from 'react-chartjs-2'
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'
import axios from 'axios'

import WhiteContainer from 'src/containers/white-container/white-container'
import Loader from '../loader/loader'

import { API_URL } from 'src/constants'
import { labelPlugins } from 'src/constants/graph-data'
import styles from './graph-bars.module.scss'

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend, ChartDataLabels)

const initData = {
  labels: ['Неделя 1', 'Неделя 2', 'Неделя 3', 'Неделя 4', 'Неделя 5', 'Неделя 6', 'Неделя 7', 'Неделя 8'],
  datasets: [
    {
      label: 'Число просмотров',
      data: [] as any[],
      borderColor: '#54D8FF',
      backgroundColor: '#54D8FF'
    }
  ]
}

const GraphBars: FC<{ id?: string | number }> = ({ id }) => {
  const [dataToGraph, setDataToGraph] = useState({ ...initData, isDataReady: false } as any)

  useEffect(() => {
    const getData = async () => {
      try {
        const res = await axios.get(`${API_URL}/api/webinarstat/getWeeklyWatchCount?id=${id}&materialType=webinar`)

        const updData = { ...dataToGraph }
        updData.datasets[0].data = res?.data
        updData.isDataReady = true

        setTimeout(() => {
          setDataToGraph(updData)
        }, 500)
      } catch (err) {
        console.log(err)
      }
    }
    if (!id) return
    getData()
  }, [id])

  const options = {
    responsive: true,
    borderRadius: 6,
    barPercentage: 0.4,
    plugins: labelPlugins,
    scales: {
      y: {
        beginAtZero: false, // Параметр: отсчет графика не с нуля
        max: Math.ceil((1.01 * dataToGraph.datasets[0].data[dataToGraph.datasets[0].data.length - 1]) / 10) * 10,
        min: dataToGraph.datasets[0].data[0] - 10
        // TODO: подумать, как получать шаг
      }
    }
  }
  return dataToGraph.isDataReady ? (
    <WhiteContainer>
      <p className={styles.graphTitle}>Охват мероприятия</p>
      <Bar options={options} data={dataToGraph} />
    </WhiteContainer>
  ) : (
    <WhiteContainer>
      <div className={styles.loaderWrapper}>
        <Loader />
      </div>
    </WhiteContainer>
  )
}

export default GraphBars
