import { FC } from 'react'
import { Link } from 'react-router-dom'

import icon from 'src/public/svg/arrowBack.svg'

import styles from './go-back.module.scss'

interface IButton {
  link: string
  txt: string
}

const GoBack: FC<IButton> = ({ link, txt }) => (
  <Link to={link}>
    <div className={styles.goBack}>
      <img src={icon} height={16} width={16} decoding="async" loading="lazy" alt="Иконка назад" />
      <span className={styles.goBackTxt}>{txt}</span>
    </div>
  </Link>
)
export default GoBack
