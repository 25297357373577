import { FC } from 'react'
import Skeleton from 'react-loading-skeleton'
import clsx from 'clsx'

import PercentTag from '../percent-tag/percent-tag'

import icon_faq from 'src/public/svg/icon_faq.svg'
import styles from './valueWidget.module.scss'

interface IValueWidget extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  title: string | undefined
  modificator?: string
  info: string |  null
  hint?: string
  benchmark?: number | null
  percentDiff?: number | null
}

const ValueWidget: FC<IValueWidget> = ({
  title,
  modificator,
  info,
  hint,
  benchmark,
  percentDiff
}) => {
  return (
    <div
      className={clsx(styles.valueWidget, {
        [modificator as string]: modificator
      })}
    >
      <div className={styles.widgetTitle}>
        <div dangerouslySetInnerHTML={{ __html: title || '' }}></div>
        {hint && (
          <div className={styles.question}>
            <img
              src={icon_faq}
              decoding="async"
              loading="lazy"
              alt="Подсказка"
              height={20}
              width={20}
            />
          </div>
        )}
        {benchmark && <div className={styles.benchmark}>Бенчмарк: {benchmark}</div>}
        {hint && <div className={styles.hint}>{hint}</div>}
      </div>
      {info === null ? (
        <div className={styles.widgetText}>
          <Skeleton count={1} />
        </div>
      ) : (
        <div className={styles.infoWrapper}>
          <div className={styles.widgetText} dangerouslySetInnerHTML={{ __html: info }} />
          {Boolean(percentDiff) && <PercentTag value={percentDiff as number} />}
        </div>
      )}
    </div>
  )
}
export default ValueWidget
