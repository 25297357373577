import axios from 'axios'
import { FC, useState, useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Helmet } from 'react-helmet-async'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import HeaderCurrentPage from 'src/components/header-current-page/header-current-page'
import CoverageWebinar from 'src/components/coverage-webinar/coverage-webinar'
import MembersReportWebinar from 'src/components/members-report-webinar/members-report-webinar'
import Funnel from 'src/components/funnel/funnel'

import { useAuth } from 'src/hooks/AuthProvider'
import { initState, ActiveBlock } from './data'
import { NOT_FOUND_URL } from 'src/constants/URLs'
import { IObj } from 'src/constants/types'
import { goBackInfo } from 'src/constants/links-data'
import { API_URL } from 'src/constants'

const Webinar: FC = () => {
  const navigate = useNavigate()
  const { userData } = useAuth()
  const { id } = useParams()

  const [statData, setStatData] = useState({ ...initState })
  const [audience, setAudience] = useState([] as any[])
  const [activeBlock, setActiveBlock] = useState(ActiveBlock.coverage)

  useEffect(() => {
    const getData = async () => {
      const getMainData = async () =>
        await axios.get(
          `${API_URL}/api/webinarstat/getMainWebinarInfo?id=${id}&sponsorId=${userData.personalId}&isAdmin=${userData.isAdmin}`
        )
      const getRecordData = async () => await axios.get(`${API_URL}/api/webinarstat/getRecordWebinarInfo?id=${id}`)
      try {
        Promise.all([getMainData(), getRecordData()]).then((values) => {
          const [resMain, resAdditional] = values
          if (resMain.data.isAccessError) {
            navigate(NOT_FOUND_URL)
            return
          }
          setStatData({ ...initState, ...resMain?.data, ...resAdditional?.data, isLoading: false })
        })
      } catch (err) {
        console.log(err)
      }
    }

    if (userData?.isHasAccess) getData()
  }, [userData])

  useEffect(() => {
    const getAverageWatchingTime = async (data: IObj<string | number | undefined>) => {
      try {
        const res = await axios.get(
          `${API_URL}/api/webinarstat/getAverageWatchingTime?materialType=${data.materialType}&id=${data.id}&isAdmin=${userData.isAdmin}`
        )
        const { ids, onlineWatchers, recordWatchers, ...other } = res?.data
        setStatData({
          ...statData,
          ...other
        })

        const specsDetermination = await axios.post(`${API_URL}/api/webinarstat/handleWebinarAudienceComposition`, {
          ids,
          onlineWatchers,
          recordWatchers
        })

        setAudience(specsDetermination?.data)
      } catch (err) {
        console.log(err)
      }
    }
    if (!statData.isLoading) {
      getAverageWatchingTime({ materialType: 'webinar', id })
    }
  }, [statData.isLoading])

  const renderActiveBlock = (block: ActiveBlock) => {
    if (userData?.isHasAccess)
      switch (block) {
        case ActiveBlock.coverage:
          return <CoverageWebinar statData={statData} id={id} />
        case ActiveBlock.report:
          return <MembersReportWebinar statData={statData} id={id} audience={audience} />
        case ActiveBlock.funnel:
          return <Funnel id={Number(id)} materialType="webinar" startDate={statData.startDate} />
      }
  }

  return (
    <>
      <Helmet>
        <title>Вебинар {id}</title>
      </Helmet>
      <HeaderCurrentPage
        goBack={goBackInfo.webinar.link}
        linkTitle={`https://medpoint.pro/webinar/${statData.linkTitle || id}`}
        txt={goBackInfo.webinar.txt}
        title={statData.webinarTitle}
        lecturs={statData.Lecturs}
        audience={statData.Specialities}
        changeBlock={setActiveBlock}
        activeBlock={activeBlock}
      ></HeaderCurrentPage>
      <WidthLimiter>{renderActiveBlock(activeBlock)}</WidthLimiter>
    </>
  )
}

export default Webinar
