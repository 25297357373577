enum vidgetName {
  views = 'views',
  uniqueViews = 'uniqueViews',
  averagePercentage = 'averagePercentage',
  likesCount = 'likesCount',
  repostsCount = 'repostsCount',
  commentsCount = 'commentsCount'
}

const materialData = {
  article: [
    { title: 'Открытий страницы', name: vidgetName.views },
    { title: 'Уникальных прочтений', name: vidgetName.uniqueViews },
    {
      title: 'Средний процент прочтения',
      name: vidgetName.averagePercentage,
      value: '%'
    },
    { title: 'Нравится', name: vidgetName.likesCount },
    { title: 'Репосты', name: vidgetName.repostsCount },
    { title: 'Комментарии', name: vidgetName.commentsCount }
  ],
  video: [
    { title: 'Открытий страницы', name: vidgetName.views },
    { title: 'Уникальных просмотров', name: vidgetName.uniqueViews },
    {
      title: 'Среднее время просмотра',
      name: vidgetName.averagePercentage
    },
    { title: 'Нравится', name: vidgetName.likesCount },
    { title: 'Репосты', name: vidgetName.repostsCount },
    { title: 'Комментарии', name: vidgetName.commentsCount }
  ]
}

export type TInitState = {
  articleTitle: string
  startDate?: Date
  linkTitle: string
  specialities: { name: string }[]
  publicationDate: null | string
  benchmark?: null | number
  [vidgetName.views]: number | null
  [vidgetName.uniqueViews]: number | null
  [vidgetName.averagePercentage]: string | number | null
  [vidgetName.likesCount]: number | null
  [vidgetName.commentsCount]: number | null
  [vidgetName.repostsCount]: number | null
}

const initState: TInitState = {
  articleTitle: '',
  linkTitle: '',
  specialities: [],
  publicationDate: null,
  views: null,
  uniqueViews: null,
  averagePercentage: null,
  likesCount: null,
  commentsCount: null,
  repostsCount: null
}

const content = {
  article: {
    helmet: 'Список статей',
    title: 'Статьи',
    helmetClickPage: 'Статья'
  },
  video: {
    helmet: 'Список видео',
    title: 'Видео',
    helmetClickPage: 'Видео'
  }
}

export { vidgetName, materialData, initState, content }
