import { clientEndpoint } from 'src/constants'

import logo from 'src/public/img/logo.png'

const LINK_TO_SALESKIT = 'https://medpoint.pro/static/medpoint_sales_kit.pdf'

const navBarTopItems = [
  {
    link: `${clientEndpoint}/webinar`,
    text: 'Вебинары',
    icon: 'webinar'
  },
  {
    link: `${clientEndpoint}/article`,
    text: 'Статьи',
    icon: 'article'
  },
  {
    link: `${clientEndpoint}/video`,
    text: 'Видео',
    icon: 'video'
  },
  {
    link: LINK_TO_SALESKIT,
    text: 'Наши услуги',
    icon: 'service'
  },
  {
    link: `${clientEndpoint}/found-bug`,
    text: 'Нашли ошибку?',
    icon: 'error'
  }
]

export { navBarTopItems, logo, LINK_TO_SALESKIT }
