import { Routes, Route, Navigate, useLocation } from 'react-router-dom'
import { useLayoutEffect, useRef, useState, useEffect } from 'react'

import AuthProvider from './hooks/AuthProvider'
import PrivateRoute from './routers/route'

import Home from './pages/home/index'
import Article from './pages/article/id'
import ArticleList from './pages/article/index'
import ArticleExample from './pages/article/example'
import Webinar from './pages/webinar/id'
import WebinarList from './pages/webinar/index'
import FoundBug from './pages/found-bug/index'
import Auth from './pages/auth/index'
import NoAccess from './pages/noaccess'
import NotFound from './pages/404'
import Navbar from './components/global/navbar/navbar'

import { clientEndpoint } from './constants'

const App = () => {
  const { pathname } = useLocation()

  useLayoutEffect(() => {
    window.scrollTo(0, 0)
  }, [pathname])

  return (
    <AuthProvider>
      <nav>
        <Navbar />
      </nav>
      <Routes>
        <Route path={`${clientEndpoint}/`} element={<Home />}>
          <Route element={<PrivateRoute />}>
            <Route path="article" element={<Home />}>
              <Route index element={<ArticleList materialType="article" />} />
              <Route path=":id" element={<Article materialType="article" />}></Route>
              <Route path="example" element={<ArticleExample materialType="article" />}></Route>
            </Route>
            <Route path="video" element={<Home />}>
              <Route index element={<ArticleList materialType="video" />}></Route>
              <Route path=":id" element={<Article materialType="video" />}></Route>
              <Route path="example" element={<ArticleExample materialType="video" />}></Route>
            </Route>
            <Route path="webinar" element={<Home />}>
              <Route index element={<WebinarList />}></Route>
              <Route path=":id" element={<Webinar />}></Route>
            </Route>
          </Route>
          <Route path="found-bug" element={<FoundBug />} />
          <Route path="login" element={<Auth />} />
          <Route path="noaccess" element={<NoAccess />} />
          <Route path="404" element={<NotFound />} />
          <Route path="*" element={<NotFound />} />
          <Route path={`${clientEndpoint}/`} element={<Navigate to={`${clientEndpoint}/webinar`} />} />
        </Route>
      </Routes>
    </AuthProvider>
  )
}

export default App
