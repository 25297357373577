import { FC, ReactNode } from 'react'
import clsx from 'clsx'
import styles from './white-container.module.scss'

interface IWhiteContainer {
  children: ReactNode
  modificator?: string
}

const WhiteContainer: FC<IWhiteContainer> = ({ modificator, children }) => (
  <div
    className={clsx(styles.whiteContainer, {
      [modificator as string]: modificator
    })}
  >
    <div>{children}</div>
  </div>
)

export default WhiteContainer
