import { FC } from 'react'

import ButtonThemes from 'src/components/parts/button/themes'
import Button from 'src/components/parts/button/button'
import { downloadResults } from 'src/components/parts/graph/utils'
import Table from 'src/components/parts/table/table'
import Warning from 'src/components/parts/warning/warning'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import { A_CLIENT_REPORT_DOWNLOAD } from 'src/constants/analytic-data'
import { TInitState } from 'src/pages/webinar/data'
import { createExcel } from 'src/utils/createXLSX/createXlsx'

import common from 'src/styles/variables/common.module.scss'
import styles from './members-report-webinar.module.scss'

interface IMembersReportWebinar {
  statData: TInitState
  id?: string
  audience: any[]
}

const MembersReportWebinar: FC<IMembersReportWebinar> = ({ statData, id, audience }) => {
  const { userData } = useAuth()

  const membersListHandler = async () => {
    await downloadResults({
      id: Number(id),
      date: statData?.startDate,
      name: statData.webinarTitle
    })
    A.setEvent(A_CLIENT_REPORT_DOWNLOAD, { name: userData?.firstName })
  }

  const downloadUsers = () => {
    const titles = ['Cпециальность', 'Запись', 'Трансляция', 'Всего врачей']
    if (audience) createExcel({ data: audience, titles, isWebinar: true, id: Number(id) })
  }

  // TODO почему тут kdebug?
  const reportBlocks = [
    {
      title: 'Отчет по мероприятию',
      desc: 'Содержит программу мероприятий, скриншоты писем и докладов с трансляции',
      btn: (
        <div className={styles.btnWrapper}>
          <Button disabled={!statData.fileStatistic.length} theme={ButtonThemes.NOBG}>
            <a
              href={statData.fileStatistic[0] && `https://kdebug.medpoint.pro/static${statData.fileStatistic[0].path}`}
              download
            >
              Отчет по мероприятию
            </a>
          </Button>
        </div>
      )
    },
    {
      title: 'Список участников',
      desc: 'Содержит id, ФИО, город, регион, специальность, место работы и время просмотра участников мероприятия',
      btn: (
        <div className={styles.btnWrapper} onClick={membersListHandler}>
          <Button theme={ButtonThemes.NOBG}>Список участников</Button>
        </div>
      )
    }
  ]

  return (
    <>
      <h2 className={common.blockName}>Состав участников</h2>
      {/* <p className={styles.dateTxt}>Данные на 20.04.2023 10:00</p> */}
      <div className={styles.contentWrap}>
        {/* <div className={styles.contentLeft}> */}
        <div className={styles.div1}>
          <Warning modificator={styles.warning} />
        </div>
        <div className={styles.div2}>
          <Table data={audience} materialType="webinar" handleClick={downloadUsers} />
        </div>
        {/* </div> */}
        <div className={styles.contentRight}>
          {reportBlocks.map((reportBlock) => {
            return (
              <div className={styles.reportWrap} key={reportBlock.title}>
                <h3 className={styles.reportTitle}>{reportBlock.title}</h3>
                <p className={styles.reportDesc}>{reportBlock.desc}</p>
                {reportBlock.btn}
              </div>
            )
          })}
        </div>
      </div>
    </>
  )
}

export default MembersReportWebinar
