import { Navigate, Outlet } from 'react-router-dom'
import { useAuth } from '../hooks/AuthProvider'
import { clientEndpoint } from 'src/constants'

const PrivateRoute = () => {
  const { userData, isAuth } = useAuth()
  if (isAuth === 'unauth')
    return (
      <>
        <Navigate to={`${clientEndpoint}/login`} replace />
        <Outlet />
      </>
    )
  if (isAuth === 'auth' && userData?.isHasAccess === false)
    return (
      <>
        <Navigate to={`${clientEndpoint}/noaccess`} replace />
        <Outlet />
      </>
    )
  return <Outlet />
}

export default PrivateRoute
