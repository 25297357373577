import { BarColors } from 'src/components/parts/bar/bar'

const SHOW_DATE = new Date('2024-02-13')
const SHOW_DATE_WEBINAR = new Date('2024-05-13')

const template = {
  article: [
    { title: 'Открытие страницы', color: BarColors.GREEN, fieldName: 'delivered' },
    { title: 'Прочтение более 50%', color: BarColors.YELLOW, fieldName: 'read' }
  ],
  video: [
    { title: 'Открытие страницы', color: BarColors.GREEN, fieldName: 'delivered' },
    { title: 'Запустили видео', color: BarColors.PINK, fieldName: 'all' },
    { title: 'Просмотр более 50%', color: BarColors.YELLOW, fieldName: 'watched' }
  ],
  webinar: [
    { title: 'Посещаемость страницы', color: BarColors.CYAN, fieldName: 'delivered' },
    { title: 'Регистрация', color: BarColors.ORANGE, fieldName: 'registrations' },
    { title: 'Посещаемость вебинара', color: BarColors.PINK, fieldName: 'pageOpen' },
    { title: 'Просмотр более 50%', color: BarColors.YELLOW, fieldName: 'longViewers' }
  ]
}

export { SHOW_DATE, SHOW_DATE_WEBINAR, template }
