type IContent = {
  [key: string]: {
    helmet: string
    title: string
    titleLink: string
    task: string
    audience: string
    txtBottom: string | null
    firstWidget: {
      title: string
      value: string | number
    }
    secondWidget: {
      title: string
      value: string | number
    }
  }
}

const content: IContent = {
  article: {
    helmet: 'Кейс компании НоваМедика',
    title: 'Кейс компании НоваМедика',
    titleLink: 'https://medpoint.pro/article/vozmozhnosti-kombinirovannoi-terapii-dementsii-888',
    task: 'Рассказать об эффективности и безопасности применения препарата Миореол (фиксированная комбинация донепезила и мемантина) в лечении деменции',
    audience: 'Неврологи, Психиатры, Гериатры, Терапевты, ВОПы',
    txtBottom:
      'Совместно с НоваМедика отработали возражения о дозировке в комментариях к публикации.',
    firstWidget: {
      title: 'Количество уникальных прочтений <br> (в первые 2 недели)',
      value: '1009'
    },
    secondWidget: {
      title: 'Средняя глубина прочтения <br>статьи',
      value: '66.35%'
    }
  },
  video: {
    helmet: 'Кейс компании Merz',
    title: 'Кейс компании Merz',
    titleLink:
      'https://medpoint.pro/article/novosti-meditsini-s-professorom-tkachevoi-vipusk-kognitivnie-narusheniya-1088',
    task: 'Ознакомить врачей с новым исследованием, посвященным мемантину - NMDA-антагонисту, и его эффективности в раннем лечении болезни Альцгеймера.',
    audience: 'Неврологи, Гериатры, Терапевты, ВОПы',
    txtBottom: null,
    firstWidget: {
      title: 'Количество уникальных просмотров (в первые 2 недели)',
      value: '820'
    },
    secondWidget: {
      title: 'Средняя продолжительность просмотра видео',
      value: '16:38'
    }
  }
}

export { content }
