import { FC, useEffect, useState } from 'react'
import clsx from 'clsx'
import axios from 'axios'

import Bar from 'src/components/parts/bar/bar'
import Loader from 'src/components/parts/loader/loader'
import BlurWrapper from 'src/components/parts/blur-wrapper/blur-wrapper'

import { useAuth } from 'src/hooks/AuthProvider'
import { SHOW_DATE, SHOW_DATE_WEBINAR, template } from './data'
import { API_URL } from 'src/constants'

import common from 'src/styles/variables/common.module.scss'
import styles from './funnel.module.scss'

const EdgeDate = {
  webinar: SHOW_DATE,
  article: SHOW_DATE,
  video: SHOW_DATE
}

interface IFunnel {
  id?: number
  materialType: 'webinar' | 'article' | 'video'
  startDate: null | undefined | string | Date
}

const Funnel: FC<IFunnel> = ({ id, materialType, startDate }) => {
  const { userData } = useAuth()
  const EDGE_DATE = EdgeDate[materialType]

  const [barData, setBarData] = useState<any>([])
  const [isLoading, setIsLoading] = useState(true)
  const [isShowGraph, setShowGraph] = useState(() =>
    Boolean(startDate && EDGE_DATE.getTime() <= new Date(startDate).getTime())
  )

  useEffect(() => {
    const getData = async () => {
      const methodName = materialType === 'webinar' ? 'webinarstat/getFunnelWebinar' : 'articlestat/getFunnel'
      if (isShowGraph) {
        try {
          const res = await axios.get(`${API_URL}/api/${methodName}?id=${id}`)
          const isWithoutStatData = materialType === 'webinar' && Boolean(startDate && SHOW_DATE_WEBINAR.getTime() >= new Date(startDate).getTime())

          if (isWithoutStatData) {
            const currTemplate = template.webinar
            const calibrationValue = res.data.registrations
            const currData = currTemplate.map((elem) => {
              const { fieldName, title, color } = elem
              if (fieldName === 'delivered') return { title, color, value: null, perc: null }
              const value = res?.data[fieldName] || 0
              const percentage = calibrationValue ? Math.round((value / calibrationValue) * 100) : null
              return { title, color, value, perc: percentage }
            })
            setBarData(currData)
          } else if (res?.data?.delivered) {
            const currTemplate = template[materialType]
            const calibrationValue = res.data.delivered
            const currData = currTemplate.map((elem) => {
              const { fieldName, title, color } = elem
              const value = res?.data[fieldName] || 0
              const percentage = Math.round((value / calibrationValue) * 100)
              return { title, color, value, perc: percentage }
            })
            setBarData(currData)
            if (userData?.isAdmin) setShowGraph(true)
          } else {
            setShowGraph(false)
          }
          setIsLoading(false)
        } catch (err) {
          console.log(err)
        }
      } else setIsLoading(false)
    }

    if (!materialType || !id) {
      setIsLoading(false)
      return
    }
    getData()
  }, [userData, id, materialType])

  return (
    <>
      <h2 className={common.blockName}>Воронка</h2>
      {/* <p className={styles.dateTxt}>Данные на 20.04.2023 10:00</p> */}
      <div className={styles.funnelWrap}>
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <div className={clsx(styles.funnelLine, styles.funnelLineHeader)}>
              <p className={styles.barTitle}>Динамика подключений</p>
              {/* Бар-заглушка, чтобы не ехала верстка */}
              <Bar mod={styles.barHeader} value={0} maxValue={1} />
              <p className={styles.values}>Количество</p>
              <p className={styles.percent}>Процент</p>
            </div>
            {isShowGraph ? (
              barData.map((bar: any) => {
                if (bar.title === 'Посещаемость страницы' && !bar.value)
                  return (
                    <div className={styles.funnelLine}>
                      <p className={styles.barTitle}>{bar.title}</p>
                      <p className={styles.fullWidth}>
                        Данные доступны для вебинаров с {SHOW_DATE_WEBINAR.toLocaleDateString('ru-RU')}
                      </p>
                      <p className={styles.values}>-</p>
                      <p className={styles.percent}>-</p>
                    </div>
                  )
                return (
                  <div className={styles.funnelLine} key={bar.title}>
                    <p className={styles.barTitle}>{bar.title}</p>
                    <Bar value={bar.perc} maxValue={100} color={bar.color} />
                    <p className={styles.values}>{bar.value}</p>
                    <p className={styles.percent}>{bar.perc ? `${bar.perc}%` : '-'}</p>
                  </div>
                )
              })
            ) : (
              <BlurWrapper>
                {startDate && EDGE_DATE.getTime() >= new Date(startDate).getTime() ? (
                  <>
                    Данные доступны для материалов, вышедших после&nbsp;
                    {EDGE_DATE.toLocaleDateString('ru-RU')}
                  </>
                ) : (
                  <>Данные появятся позднее</>
                )}
              </BlurWrapper>
            )}
          </>
        )}
      </div>
    </>
  )
}

export default Funnel
