import { useState, useEffect, useRef } from 'react'
import { Helmet } from 'react-helmet-async'
import axios from 'axios'

import WidthLimiter from 'src/containers/width-limiter/width-limiter'
import InfoWidget from 'src/components/parts/infoWidget/infoWidget'
import SwitchToggle from 'src/components/parts/switch-toggle/switch-toggle'
import Loader from 'src/components/parts/loader/loader'
import useInfiniteScroll from 'src/hooks/useInfiniteScroll'

import { useAuth } from 'src/hooks/AuthProvider'
import A from 'src/utils/metrics/analytic'
import {
  A_CLIENT_WEBINAR_FUTURE_OPEN,
  A_CLIENT_WEBINAR_PAST_OPEN
} from 'src/constants/analytic-data'
import { formatDate } from 'src/utils/dateFormatter'
import { formatLectursName } from 'src/utils/prepareData'
import { options, typeOptions } from './data'
import { IListWebinar } from 'src/constants/types'
import { API_URL } from 'src/constants'

import commom from 'src/styles/variables/common.module.scss'
import styles from 'src/styles/webinar-page/webinar-page.module.scss'

let STOP_FETCH = false

const WebinarList = () => {
  const { userData } = useAuth()

  const [list, setList] = useState<IListWebinar[]>([])
  const [selectedOption, setSelectedOption] = useState(options[0])
  const [isLoading, setIsLoading] = useState(true)
  const [loadMore, setLoadMore] = useState(false)
  const [subString, setSubString] = useState('')

  const containerRef = useRef<HTMLDivElement>(null)

  const handleChange = (event: any) => setSubString(event.target.value)

  const getData = async ({
    limit,
    offset,
    searchSubString
  }: {
    limit?: number
    offset?: number
    searchSubString?: string
  }) => {
    console.log({userData})
    try {
      const res = await axios.get(
        `${API_URL}/api/webinarstat/getWebinarList?limit=${limit || 10}&offset=${offset || 0}&searchSubString=${searchSubString}&type=${typeOptions(selectedOption)}&sponsorId=${userData.personalId}&isAdmin=${Boolean(userData.isAdmin)}`
      )
      if (res.data?.length === 0) STOP_FETCH = true
      setList([...list, ...res.data])
      setIsLoading(false)
      setLoadMore(false)
    } catch (err) {
      console.log(err)
    }
  }

  const googleAnalyticHandler = () => {
    if (typeOptions(selectedOption) === 'past') {
      return A.setEvent(A_CLIENT_WEBINAR_PAST_OPEN, { name: userData?.firstName })
    } else {
      return A.setEvent(A_CLIENT_WEBINAR_FUTURE_OPEN, { name: userData?.firstName })
    }
  }

  const linkWebinar = (webinar: IListWebinar) => {
    if (typeOptions(selectedOption) === 'past') return `./${webinar.id}`
    return `https://medpoint.pro/webinar/${webinar.linkTranslation}`
  }

  const handleScrollLimit = () => {
    if (!STOP_FETCH) {
      setLoadMore(true)
      getData({ limit: 5, offset: list.length, searchSubString: subString })
    }
  }

  useInfiniteScroll(containerRef, list, handleScrollLimit)

  //Перерендер на изменение пути
  useEffect(() => {
    setList([])
    setIsLoading(true)
    STOP_FETCH = false
  }, [selectedOption, subString])

  useEffect(() => {
    if (!isLoading || !userData?.isHasAccess) return
    getData({ limit: 5, offset: list.length, searchSubString: subString })
  }, [isLoading, userData])

  return (
    <>
      <Helmet>
        <title>Список вебинаров</title>
      </Helmet>
      <WidthLimiter>
        <div className={styles.headerWrapper}>
          <p className={commom.headerPage}>Вебинары</p>
          {userData?.isAdmin && (
            <input
              value={subString}
              className={styles.searchField}
              type="text"
              onChange={handleChange}
            />
          )}
        </div>
        <div className={styles.toggleWrapper}>
          <SwitchToggle
            options={options}
            selectedOption={selectedOption}
            handleToggle={setSelectedOption}
          />
        </div>
        {list.length ? (
          <div className={commom.postWrapper} ref={containerRef}>
            {list.map((webinar: IListWebinar) => {
              return (
                <InfoWidget
                  key={webinar._id}
                  topTxt={formatDate(new Date(webinar.startDate))}
                  middleTxt={webinar.name}
                  bottomTxt={formatLectursName(webinar.Lecturs)}
                  widgetLink={linkWebinar(webinar)}
                  isNewTab={typeOptions(selectedOption) === 'future'}
                  onClick={googleAnalyticHandler}
                />
              )
            })}
            {loadMore && (
              <div className={styles.loaderWrapper}>
                <Loader />
              </div>
            )}
          </div>
        ) : isLoading ? (
          <Loader />
        ) : (
          <div>Нет вебинаров</div>
        )}
      </WidthLimiter>
    </>
  )
}

export default WebinarList
