import { FC, ReactNode } from 'react'
import clsx from 'clsx'

import WhiteContainer from 'src/containers/white-container/white-container'

import styles from './popup-wrapper.module.scss'

interface IProps {
  active: boolean
  children: ReactNode
  handlePopup?: (e: React.MouseEvent<HTMLDivElement>) => void
}

const PopupWrapper: FC<IProps> = ({ active, handlePopup, children }) => (
  <>
    <div
      className={clsx(styles.popupOverlay, {
        [styles.active]: active
      })}
      onClick={handlePopup}
    ></div>
    <div
      className={clsx(styles.popupContent, {
        [styles.active]: active
      })}
      onClick={(e) => e.stopPropagation()}
    >
      <WhiteContainer modificator={styles.whiteConteiner}>
        <div className={styles.popupClose} onClick={handlePopup} />
        {children}
      </WhiteContainer>
    </div>
  </>
)
export default PopupWrapper
