import { FC } from 'react'
import clsx from 'clsx'
import InputMask from 'react-input-mask'

import styles from './input-validation-react.module.scss'

const FieldsNeedToBeCapitalized = ['Фамилия', 'Имя', 'Отчество']
const FieldsNeedToBeLowercase = ['Email']
interface IValidationInput extends React.InputHTMLAttributes<HTMLInputElement> {
  nameInput: string
  error?: any
  mask?: string
  touched?: boolean | undefined
  modificator?: string | boolean
  labelModificator?: string
  inputNameModificator?: string
  innerContainerModificator?: string
  inputWrapperModificator?: string
  errorModificator?: string
  setInputRef?: (element: HTMLInputElement) => void
  children?: React.ReactNode
}

const ValidationInput: FC<IValidationInput> = ({
  nameInput,
  value,
  placeholder,
  error,
  mask,
  touched,
  modificator,
  labelModificator,
  inputNameModificator,
  innerContainerModificator,
  inputWrapperModificator,
  errorModificator,
  children,
  setInputRef,
  ...props
}) => {
  return (
    <div
      className={clsx(styles.innerContainer, {
        [innerContainerModificator as string]: innerContainerModificator
      })}
    >
      <label
        className={clsx(styles.labelInput, {
          [labelModificator as string]: labelModificator
        })}
      >
        <p
          className={clsx(styles.outerName, {
            [inputNameModificator as string]: inputNameModificator
          })}
        >
          {nameInput}
        </p>

        <div
          className={clsx(styles.inputWrapper, {
            [inputWrapperModificator as string]: inputWrapperModificator
          })}
        >
          <InputMask
            className={clsx(styles.input, {
              [styles.error]: error && touched,
              [modificator as string]: modificator,
              [styles.capitalized]: FieldsNeedToBeCapitalized.includes(nameInput),
              [styles.tolowercase]: FieldsNeedToBeLowercase.includes(nameInput)
            })}
            {...props}
            value={value || ''}
            placeholder={placeholder}
            mask={mask || ''}
            inputRef={setInputRef}
          />
          {children}
        </div>
      </label>
      {touched && error?.message ? (
        <div>
          <p
            className={clsx(styles.errorCheck, {
              [errorModificator as string]: errorModificator
            })}
          >
            {error?.message}
          </p>
        </div>
      ) : null}
    </div>
  )
}

export default ValidationInput
