import { FC } from 'react'
import { Link } from 'react-router-dom'
import clsx from 'clsx'

import { ReactComponent as WebinarIcon } from 'src/public/svg/webinar_icon.svg'
import { ReactComponent as ArticleIcon } from 'src/public/svg/article_icon.svg'
import { ReactComponent as VideoIcon } from 'src/public/svg/video_icon.svg'
import { ReactComponent as ServicesIcon } from 'src/public/svg/services_icon.svg'
import { ReactComponent as ErrorIcon } from 'src/public/svg/error_icon.svg'
import { ReactComponent as LogoutIcon } from 'src/public/svg/logout.svg'

import { LINK_TO_SALESKIT } from '../data'
import styles from '../navbar.module.scss'

interface IMenuItem {
  link: string
  text: string
  disabled?: boolean
  page: string
  icon: string
}

interface IMenuItemText {
  Icon: React.ReactElement
  text: string
}

const MenuItemText: FC<IMenuItemText> = ({ Icon, text }) => {
  return (
    <li className={styles.listElement}>
      {Icon}
      <span className={styles.listElementTxt}>{text}</span>
    </li>
  )
}

const MenuItem: FC<IMenuItem> = ({ link, text, disabled, icon, page }) => {
  const iconSvg = (icon: string) => {
    switch (icon) {
      case 'webinar':
        return <WebinarIcon />
      case 'article':
        return <ArticleIcon />
      case 'video':
        return <VideoIcon />
      case 'service':
        return <ServicesIcon />
      case 'error':
        return <ErrorIcon />
      case 'logout':
        return <LogoutIcon />
      default:
        return <WebinarIcon />
    }
  }
  return (
    <>
      {link === LINK_TO_SALESKIT ? (
        <a className={styles.listItem} href={`${LINK_TO_SALESKIT}`} target="_blank" rel="noreferrer">
          <MenuItemText text={text} Icon={iconSvg(icon)} />
        </a>
      ) : (
        <Link
          className={clsx(styles.listItem, {
            [styles.listItemDisable]: disabled,
            [styles.listItemActive]: page.includes(link)
          })}
          to={link}
          key={text}
        >
          <MenuItemText text={text} Icon={iconSvg(icon)} />
        </Link>
      )}
    </>
  )
}

export { MenuItemText }
export default MenuItem
